import { useEffect, useState } from "react";
import { Tab } from "../../utils/types";
import clsx from "clsx";
import { HorizontalRule } from "./Fonts";

interface Props {
  tabs: Tab[];
  activeIndex?: number;
}

const Tabs = ({ tabs, activeIndex = 0 }: Props) => {
  const [activeTab, setActiveTab] = useState(activeIndex);

  useEffect(() => {
    setActiveTab(0);
  }, [activeIndex]);

  const activeContent = () => {
    return tabs[activeTab]?.content;
  };

  const isActive = (index: number) => {
    return index === activeTab;
  };

  return (
    <>
      <div
        className={
          "flex items-stretch rounded-md justify-stretch border border-gray-300 w-full p-1"
        }
      >
        {tabs.map((tab, index) => {
          return (
            <button
              key={"tab-" + index}
              id={"tab-" + index}
              onClick={() => setActiveTab(index)}
              className={clsx(
                "w-auto rounded-lg transition-all hover:text-black bg-transparent text-gray-500  block py-2 px-4 grow",
                isActive(index) && "!bg-gray-900 text-white hover:text-white"
              )}
            >
              {tab.label}
            </button>
          );
        })}
      </div>
      <HorizontalRule />
      <div>{activeContent()}</div>
    </>
  );
};

export default Tabs;
