import { ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import SelfServiceAuth from "../../services/SelfServiceAuth";
import Helmet from "react-helmet";
import GuestLayout from "../layout/GuestLayout";
import LoadingStates from "../ui/LoadingStates";
import {
  getToken,
  hasValidToken,
  infoIsChecked,
} from "../../store/reducers/auth/authSlice";
import { useNavigate } from "react-router-dom";

interface Props {
  children: ReactNode;
}

const ValidateDomain = ({ children }: Props) => {
  const [infoLoaded, setInfoLoaded] = useState(false);
  const dispatch = useAppDispatch();
  const infoChecked = useAppSelector(infoIsChecked);
  const navigate = useNavigate();
  const tokenIsValid = useAppSelector(hasValidToken);
  const token = useAppSelector(getToken);
  const info = useAppSelector((state) => state.auth.info);

  const checkInfo = async () => {
    if (infoChecked) {
      return;
    }

    await new SelfServiceAuth()
      .info(tokenIsValid ? token : null)
      .then((res) => {
        dispatch({
          type: "auth/setCompanyInfo",
          payload: res,
        });
      })
      .catch((err) => {
        navigate("/not-found");
      })
      .finally(() => {
        dispatch({
          type: "auth/setInfoChecked",
          payload: true,
        });
        setInfoLoaded(true);
      });
  };

  useEffect(() => {
    if (!infoLoaded) {
      checkInfo();
    }
  }, [infoLoaded]);

  useEffect(() => {
    if (!tokenIsValid) {
      // delete localStorage.token;
      localStorage.removeItem("token");
      //navigate("/login");
    }
  }, [hasValidToken]);

  return (
    <>
      <Helmet>
        {!info && <title>Fenerum Self-Service</title>}
        {info && <title>{info?.name} - Self-Service</title>}
      </Helmet>
      {children}
    </>
  );
};

export default ValidateDomain;
