import Label from "./Label";
import clsx from "clsx";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { InputProps } from "../../utils/types";

const InputField = ({
  register,
  name,
  rules = {},
  value = "",
  placeholder,
  label = null,
  required = false,
  errors = null,
  type = "text",
}: InputProps) => {
  return (
    <div className={"mb-4"}>
      {label && <Label labelFor={name ?? ""}>{label}</Label>}
      <div className={"relative"}>
        <input
          {...register(name ?? "", { required, ...rules })}
          type={type}
          placeholder={placeholder ?? ""}
          id={name ?? ""}
          className={clsx(
            "w-full rounded-md h-10 border-gray-300 focus:ring-0 focus:border-black px-4 py-2 placeholder-gray-500 ",
            errors &&
              errors[name ?? ""] &&
              "border-red-500 focus:border-red-500"
          )}
        />
        {errors && errors[name ?? ""] && (
          <div className={"absolute inset-y-0 right-0 pr-3 flex items-center"}>
            <ExclamationCircleIcon className={"w-5 h-5 text-red-500"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default InputField;
