import { CSSProperties, ReactNode } from "react";
import clsx from "clsx";

class TextProps {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
}

export const PageTitle = ({ children, className, style }: TextProps) => {
  return (
    <h2
      className={clsx(
        "text-xl md:text-2xl font-medium text-gray-900 mb-2 antialiased",
        className
      )}
      style={style}
    >
      {children}
    </h2>
  );
};

export const SectionHeading = ({ children, className }: TextProps) => {
  return (
    <h2
      className={clsx(
        "text-lg md:text-xl font-medium text-gray-900 mb-4 antialiased",
        className
      )}
    >
      {children}
    </h2>
  );
};

export const Subheading = ({ children, className }: TextProps) => {
  return (
    <h3
      className={clsx(
        "text-base font-medium text-gray-900 mb-4 antialiased",
        className
      )}
    >
      {children}
    </h3>
  );
};

export const Paragraph = ({ children, className }: TextProps) => {
  return (
    <p className={clsx("text-gray-400 text-sm mb-2 antialiased", className)}>
      {children}
    </p>
  );
};

export const HorizontalRule = () => {
  return <hr className={"border-gray-300 my-4 lg:my-6 xl:my-8"} />;
};
