export default function useCreditCardMask() {
  const cardNumberMask = (value: string) => {
    // only numbers
    value = value.replace(/\D/g, "");

    // max length
    if (value.length > 16) {
      value = value.slice(0, 16);
    }

    value = value
      .replace(/\s/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();

    return value;
  };

  const expirationDateMask = (value: string) => {
    // only numbers
    value = value.replace(/\D/g, "");

    // max length
    if (value.length > 4) {
      value = value.slice(0, 4);
    }

    // MM can only be 01-12
    if (value.length > 2) {
      const month = parseInt(value.slice(0, 2));
      if (month > 12) {
        value = "12";
      }
    }

    value = value
      .replace(/(\d{2})(\d{2})/, "$1 / $2")
      .replace(/\s/g, "")
      .trim();

    return value;
  };

  const cvcMask = (value: string) => {
    // only numbers
    value = value.replace(/\D/g, "");

    // max length
    if (value.length > 3) {
      value = value.slice(0, 3);
    }

    return value;
  };

  return {
    cardNumberMask,
    expirationDateMask,
    cvcMask,
  };
}
