import FenerumAPIService from "./FenerumAPIService";
import { useAuth } from "../hooks/useAuth";

type Token = string;

class SelfServiceAuth extends FenerumAPIService {
  info = async (token?: string | null) => {
    const { organizationSlug, decodedToken } = useAuth();
    console.log("decodedToken", decodedToken);
    return await this.get(
      `self-service/${organizationSlug}/info/`,
      {},
      token
        ? {
            Authorization: `Self-Service-Token ${token}`,
          }
        : {}
    );
  };

  // To initiate the self service flow, you need to call the following endpoint:
  initiateSelfService = async (email: string) => {
    const { organizationSlug } = useAuth();

    return await this.post(`self-service/${organizationSlug}/initiate/`, {
      email,
    });
  };

  // To validate the token, you need to call the following endpoint:
  validate = async (token: string) => {
    const { organizationSlug } = useAuth();
    return await this.post(`self-service/${organizationSlug}/validate/`, {
      initiation_code: token,
    })
      .then((response) => {
        return response?.token as Token;
      })
      .catch(() => {
        throw new Error("Link has expired. Please try again.");
      });
  };
}

export default SelfServiceAuth;
