import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AccountService from "../../../services/AccountService";

export interface AccountData {
  uuid?: string;
  company_name: string;
  legal_address: string;
  legal_city: string;
  legal_zipcode: string;
  legal_country: string;
  legal_vat_number: string;
  code?: string;
  balance: number;
}

interface AccountState {
  account: null | AccountData; // todo make account type
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: AccountState = {
  account: null,
  status: "idle",
  error: null,
};

export const fetchAccount = createAsyncThunk(
  "account/fetchAccount",
  async (accountCode: string) => {
    return new AccountService().getAccountByCode(accountCode);
  }
);

interface UpdateAccountProps {
  code: string | undefined;
  data: AccountData | null;
}

export const updateAccount = createAsyncThunk(
  "account/updateAccount",
  async ({ code, data }: UpdateAccountProps) => {
    return new AccountService().updateAccount(code, data);
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAccount.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateAccount.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAccount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.account = action.payload;
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.account = action.payload;
      })
      .addCase(
        fetchAccount.rejected || updateAccount.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action?.error?.message || null;
        }
      );
  },
});

export const {} = accountSlice.actions as any;

export default accountSlice.reducer;
