import { useNavigate, useParams } from "react-router-dom";
import GuestLayout from "../../layout/GuestLayout";
import { Suspense, useEffect, useState } from "react";
import { SectionHeading } from "../../ui/Fonts";
import GoBackLink from "../../ui/GoBackLink";
import { useAppDispatch } from "../../../store/hooks";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { validateTokenAndSetInfo } from "../../../services/AuthService";

const ValidateCodeView = () => {
  const [error, setError] = useState<string>("");
  const [hasValidated, setHasValidated] = useState<boolean>(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const [value, setValue] = useLocalStorage("token", null);

  useEffect(() => {
    if (!hasValidated) {
      setHasValidated(true);
      validateTokenAndSetInfo(token || "", dispatch, setValue).catch(
        (error) => {
          console.log(error.message);
          setError(error.message);
        }
      );
    }
  }, [dispatch, hasValidated, setValue, token, value]);

  return (
    <GuestLayout>
      <div className={"text-center"}>
        <Suspense>
          <SectionHeading>{error || "Validating..."}</SectionHeading>
          {error && (
            <GoBackLink onClick={() => navigate("/login")}>
              Back to login
            </GoBackLink>
          )}
        </Suspense>
      </div>
    </GuestLayout>
  );
};

export default ValidateCodeView;
