import React, { ReactNode, useState } from "react";
import { Paragraph, Subheading } from "./Fonts";
import Tag, { TagProps } from "./Tag";
import ActionDotsIcon from "./icons/ActionDotsIcon";
import { Link } from "react-router-dom";
import TrashIcon from "./icons/TrashIcon";
import clsx from "clsx";
import PencilIcon from "./icons/PencilIcon";

interface Props {
  title: string;
  icon?: ReactNode | null;
  description?: string;
  descriptionColor?: "red" | null;
  editLink?: string | null;
  deleteLink?: string | null;
  tag?: TagProps | null;
}

const ActionTile = ({
  title,
  description,
  icon = null,
  editLink = null,
  descriptionColor = null,
  deleteLink = null,
  tag = null,
}: Props) => {
  const [showActions, setShowActions] = useState(false);
  return (
    <div
      className={
        "border border-gray-300 rounded-lg p-4 md:p-6 flex items-center mb-4"
      }
    >
      {icon && <div className={"mr-4 flex-shrink-0"}>{icon}</div>}
      <div className={"flex-grow"}>
        <div className={"flex items-center space-x-2 mb-1"}>
          <Subheading className={"!mb-0"}>{title}</Subheading>
          {tag && <Tag color={tag.color}>{tag.text}</Tag>}
        </div>
        {description && (
          <Paragraph
            className={clsx(
              "!mb-0",
              descriptionColor === "red" && "text-red-500"
            )}
          >
            {description}
          </Paragraph>
        )}
      </div>
      <div className={"relative"}>
        {editLink && deleteLink && (
          <button
            className={
              "hover:bg-gray-50 w-10 h-10 block rounded-full transition-all flex items-center justify-center"
            }
            onClick={() => setShowActions(!showActions)}
          >
            <ActionDotsIcon className={"w-8 h-8"} />
          </button>
        )}
        {!editLink && deleteLink && (
          <Link
            to={deleteLink}
            className={
              "hover:bg-gray-50 w-10 h-10 block rounded-full transition-all flex items-center justify-center"
            }
          >
            <TrashIcon className={"w-8 h-8"} />
          </Link>
        )}
        {editLink && !deleteLink && (
          <Link
            to={editLink}
            className={
              "hover:bg-gray-50 w-10 h-10 block rounded-full transition-all flex items-center justify-center"
            }
          >
            <PencilIcon className={"block w-4 h-4"} />
          </Link>
        )}
        {showActions && (
          <div
            className={
              "absolute top-8 divide-y rounded-lg border border-gray-200 shadow divide-gray-200 bg-white "
            }
          >
            {editLink && (
              <Link
                to={editLink}
                className={"py-1.5 px-3 text-sm block hover:bg-gray-50"}
              >
                Edit
              </Link>
            )}
            {deleteLink && (
              <Link
                to={deleteLink}
                className={
                  "py-1.5 px-3 text-sm block text-red-500 hover:bg-gray-50"
                }
              >
                Cancel
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionTile;
