interface IconProps {
  className?: string;
}

const LogoutIcon = (props: IconProps) => (
  <svg
    width="12"
    height="13"
    className={props.className}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_107_417)">
      <path
        d="M8.143 9.50002V11.2143C8.143 11.4416 8.05269 11.6596 7.89195 11.8204C7.7312 11.9811 7.51318 12.0714 7.28585 12.0714H1.28585C1.05853 12.0714 0.840508 11.9811 0.679762 11.8204C0.519017 11.6596 0.428711 11.4416 0.428711 11.2143V1.78573C0.428711 1.5584 0.519017 1.34039 0.679762 1.17964C0.840508 1.01889 1.05853 0.928589 1.28585 0.928589H7.28585C7.51318 0.928589 7.7312 1.01889 7.89195 1.17964C8.05269 1.34039 8.143 1.5584 8.143 1.78573V3.50002"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.57129 6.5H11.5713"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.85742 4.78571L11.5717 6.49999L9.85742 8.21428"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_107_417">
        <rect
          width="12"
          height="12"
          fill="currentColor"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LogoutIcon;
