import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PaymentMethodService from "../../../services/PaymentMethodService";
import { RootState } from "../../index";
import StripeService, {
  CreateCardTokenData,
} from "../../../services/StripeService";
import { Status } from "../../../utils/types";

export interface PaymentCardData {
  uuid: string;
  active: boolean;
  brand: string;
  card_number: string;
  month: number;
  year: number;
  payment_gateway: string;
  payment_gateway_id: string;
}

interface PaymentMethodState {
  paymentMethods: PaymentCardData[] | null;
  paymentMethod: any | null;
  status: Status;
  getStatus: Status;
  createStatus: Status;
  updateStatus: Status;
  error: string | null;
}

const initialState: PaymentMethodState = {
  paymentMethods: null,
  paymentMethod: null,
  status: "idle",
  getStatus: "idle",
  createStatus: "idle",
  updateStatus: "idle",
  error: null,
};

export const fetchPaymentCards = createAsyncThunk(
  "paymentMethods/fetchPaymentCards",
  async () => {
    return new PaymentMethodService().getPaymentCards();
  }
);

export interface CreatePaymentCardData {
  account_id: string;
  stripe_token: string;
  card_data: CreateCardTokenData;
}

export const createPaymentCard = createAsyncThunk(
  "paymentMethods/createPaymentCard",
  async (data: CreatePaymentCardData) => {
    const stripeService = new StripeService(data.stripe_token);
    const cardToken = await stripeService.createCardToken(
      data.card_data as CreateCardTokenData
    );
    const paymentMethod = await stripeService.createPaymentMethod(cardToken.id);

    return new PaymentMethodService().createPaymentCard(
      data.account_id,
      paymentMethod.id
    );
  }
);

export const getPaymentCard = createAsyncThunk(
  "paymentMethods/getPaymentCard",
  async (uuid: string) => {
    return new PaymentMethodService().getPaymentCard(uuid);
  }
);

export const disablePaymentCard = createAsyncThunk(
  "paymentMethods/disablePaymentCard",
  async (uuid: string) => {
    return new PaymentMethodService().disablePaymentCard(uuid);
  }
);

const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {
    resetPaymentMethod: (state) => {
      state.paymentMethod = null;
      state.updateStatus = "idle";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPaymentCards.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPaymentCards.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.paymentMethods = action.payload;
      })
      .addCase(fetchPaymentCards.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error?.message || null;
      })
      .addCase(createPaymentCard.pending, (state, action) => {
        state.createStatus = "loading";
      })
      .addCase(createPaymentCard.fulfilled, (state, action) => {
        state.createStatus = "succeeded";
        state.paymentMethods = state.paymentMethods
          ? [action.payload, ...state.paymentMethods]
          : [action.payload];
      })
      .addCase(createPaymentCard.rejected, (state, action) => {
        state.createStatus = "failed";
        state.error = action?.error?.message || null;
      })
      .addCase(disablePaymentCard.pending, (state, action) => {
        state.updateStatus = "loading";
      })
      .addCase(disablePaymentCard.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
      })
      .addCase(disablePaymentCard.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.error = action?.error?.message || null;
      })
      .addCase(getPaymentCard.pending, (state, action) => {
        state.getStatus = "loading";
      })
      .addCase(getPaymentCard.fulfilled, (state, action) => {
        state.getStatus = "succeeded";
        state.paymentMethod = action.payload;
      })
      .addCase(getPaymentCard.rejected, (state, action) => {
        state.getStatus = "failed";
        state.error = action?.error?.message || null;
      });
  },
});

export const getPaymentCardsSortedByActive = (state: RootState) => {
  if (!state.paymentMethods.paymentMethods) return null;

  const activeCards = state.paymentMethods.paymentMethods.filter(
    (card) => card.active
  );

  const inactiveCards = state.paymentMethods.paymentMethods.filter(
    (card) => !card.active
  );

  return [...activeCards, ...inactiveCards];
};

export default paymentMethodsSlice.reducer;

export const { resetPaymentMethod } = paymentMethodsSlice.actions;
