import Tabs from "../../ui/Tabs";
import { Plan, Tab } from "../../../utils/types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchPlans,
  getStatus,
  getPlanTermIntervals,
  getPlanByInterval,
  planTermsHasMultipleCurrencies,
} from "../../../store/reducers/plans/plansSlice";
import { useEffect, useState } from "react";
import RadioField from "../../ui/RadioField";
import { useForm } from "react-hook-form";
import { HorizontalRule } from "../../ui/Fonts";
import LoadingStates from "../../ui/LoadingStates";
import useFormats from "../../../hooks/useFormats";
import PlanTermOption from "../../ui/PlanTermOption";
import {
  AccountStep,
  getSelectedPlanTerm,
  setPlanTerm,
  setQuantity,
} from "../../../store/reducers/checkout/checkoutSlice";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import {
  getSubscription,
  subscriptionIsLoading,
} from "../../../store/reducers/subscriptions/subscriptionSlice";
import { useParams } from "react-router-dom";

interface Props {
  step?: AccountStep;
}

const SetPlansForm = ({ step = "plans" }: Props) => {
  const dispatch = useAppDispatch();
  const plansByInterval = useAppSelector(getPlanByInterval);
  const hasMultipleCurrencies = useAppSelector(planTermsHasMultipleCurrencies);
  const plansStatus = useAppSelector(getStatus);
  const currency = useAppSelector((state) => state.plans.currency);

  const [planFetched, setPlanFetched] = useState(false);
  const planTypes = useAppSelector(getPlanTermIntervals);
  const isLoading = plansStatus === "loading";
  const subscription = useAppSelector(
    (state) => state.subscriptions.subscription
  );
  const subscriptionsLoading = useAppSelector(subscriptionIsLoading);
  const { planTypeName } = useFormats();
  const { uuid } = useParams();
  const selectedPlanTerm = useAppSelector(getSelectedPlanTerm);
  const [value, setValue] = useLocalStorage("planTermUuid", null);

  const [activePlanIndex, setActivePlanIndex] = useState(0);

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      plans: [],
    },
  });

  // watch planTerm changes

  useEffect(() => {
    if (plansStatus === "idle") {
      dispatch<any>(fetchPlans());
    }
    if (step === "edit-plan") {
      if (typeof uuid === "string" && !planFetched) {
        setPlanFetched(true);
        dispatch<any>(getSubscription(uuid));
      }
    }
  }, [plansStatus, dispatch, step, uuid]);

  useEffect(() => {
    if (subscription) {
      dispatch<any>(setPlanTerm(subscription.terms.uuid));
      setValue(subscription.terms.uuid);
      dispatch<any>(setQuantity(subscription.quantity));
    }
  }, [subscription]);

  const setPlanTermUuid = (planTermUuid: string) => {
    dispatch<any>(setPlanTerm(planTermUuid));
    setValue(planTermUuid);
  };

  useEffect(() => {
    if (currency) {
      setActivePlanIndex(0);
    }
  }, [currency]);

  useEffect(() => {
    if (!hasMultipleCurrencies) return;
    dispatch({ type: "plans/showCurrencySelector", payload: true });
    return () => {
      console.log("unmount");
      dispatch({ type: "plans/showCurrencySelector", payload: false });
    };
  }, [hasMultipleCurrencies, dispatch]);

  const tabs: Tab[] | null = planTypes.map((planType) => {
    return {
      label: planTypeName(planType),
      content: (
        <div className={"space-y-4"}>
          {plansByInterval(planType).map((option: Plan, index) => {
            return (
              <div key={option.uuid}>
                {option.planterms_set &&
                  option.planterms_set.map((planTerm) => {
                    // if (selectedPlanTerm === planTerm)
                    //   setActivePlanIndex(index);
                    return (
                      <RadioField
                        key={planTerm.uuid}
                        isSelected={selectedPlanTerm === planTerm}
                        onChange={(value) => setPlanTermUuid(value)}
                        name={"planTerms"}
                        value={planTerm.uuid}
                      >
                        <PlanTermOption
                          title={option.name}
                          description={option.description}
                          price={parseFloat(planTerm.price)}
                          currency={planTerm.currency}
                        />
                      </RadioField>
                    );
                  })}
              </div>
            );
          })}
        </div>
      ),
    };
  });

  if (isLoading || subscriptionsLoading || tabs.length === 0)
    return <LoadingSkeleton />;

  return <Tabs tabs={tabs} activeIndex={activePlanIndex} />;
};

const LoadingSkeleton = () => {
  return (
    <div className={"space-y-8"}>
      <LoadingStates count={1} height={50} />
      <HorizontalRule />
      <LoadingStates count={5} height={80} />
    </div>
  );
};

export default SetPlansForm;
