import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store";
import { getPlanTermCurrencies } from "../../store/reducers/plans/plansSlice";
import { useEffect } from "react";
import FlagByCurrency from "./icons/Flag";
import clsx from "clsx";
import { setPlanTerm } from "../../store/reducers/checkout/checkoutSlice";
import { useParams } from "react-router-dom";

const CurrencySelector = () => {
  const showCurrencySelector = useAppSelector(
    (state: RootState) => state.plans.showCurrencySelector
  );
  const currencies = useAppSelector(getPlanTermCurrencies);
  const currency = useAppSelector((state: RootState) => state.plans.currency);
  const dispatch = useAppDispatch();

  // if path has uuid do not show currency selector
  const { uuid } = useParams();

  console.log("uuid", uuid);

  useEffect(() => {
    if (currency === null && currencies.length > 0) {
      dispatch({ type: "plans/setCurrency", payload: currencies[0] });
    }
  }, [currency, currencies, dispatch]);

  if (!showCurrencySelector || currencies.length === 0 || uuid) return null;

  return (
    <div className={"relative"}>
      {currency && (
        <span
          className={"absolute left-0 top-0 bottom-0 flex items-center pl-4"}
        >
          <FlagByCurrency currency={currency} />
        </span>
      )}
      <select
        onChange={(e) => {
          dispatch({ type: "plans/setCurrency", payload: e.target.value });
          dispatch<any>(setPlanTerm(null));
        }}
        value={currency ?? ""}
        className={clsx(
          "w-full rounded-md h-11 border-gray-300 focus:ring-0 focus:border-black pr-10 px-4 py-2 placeholder-gray-500",
          currency && "pl-10"
        )}
      >
        {currencies.map((currency) => (
          <option key={currency} value={currency}>
            {currency}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CurrencySelector;
