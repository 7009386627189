const Envelope = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_115_2425)">
        <path
          d="M9.97394 18.8434L13.7145 22.5789C13.9467 22.8111 14.2344 22.9801 14.5503 23.0699C14.8662 23.1596 15.1998 23.1672 15.5194 23.0918C15.839 23.0164 16.1341 22.8605 16.3765 22.6391C16.619 22.4176 16.8009 22.1378 16.9048 21.8263L23.0437 3.39259C23.157 3.05327 23.1736 2.68908 23.0915 2.34086C23.0095 1.99265 22.832 1.67419 22.579 1.42122C22.326 1.16825 22.0076 0.990775 21.6594 0.908703C21.3112 0.826632 20.947 0.843213 20.6077 0.956587L2.17394 7.10059C1.86341 7.20493 1.58462 7.38673 1.36389 7.62879C1.14317 7.87086 0.9878 8.1652 0.912472 8.48401C0.837145 8.80282 0.844343 9.13558 0.933388 9.45083C1.02243 9.76609 1.19038 10.0534 1.42137 10.2857L6.12023 14.9897L5.9608 20.9297L9.97394 18.8434Z"
          stroke="#222222"
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.4933 1.34058L6.12012 14.9897"
          stroke="#222222"
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_115_2425">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Envelope;
