import FenerumAPIService from "./FenerumAPIService";
import { CreateAccountData } from "../store/reducers/checkout/checkoutSlice";
import { AccountData } from "../store/reducers/account/accountSlice";
import toast from "react-hot-toast";

interface AccountCreatedResponse {
  uuid: string;
  company_name: string;
  code: string;
}

interface RecipientCreatedResponse {
  uuid: string;
  account: string;
  name: string;
  email: string;
}

class AccountService extends FenerumAPIService {
  createAccount = async (data: CreateAccountData | null) => {
    if (!data) throw new Error("No data provided");

    return await this.post("v1/accounts", {
      company_name: data.companyName,
      legal_address: data.legalAddress,
      legal_city: data.legalCity,
      legal_zipcode: data.legalZipCode,
      legal_country: data.country,
      legal_vat_number: data.vatNumber,
      recipient: {
        name: data.firstName + " " + data.lastName,
        email: data.email,
        receive_invoice: true,
        receive_payment_confirmation: true,
      },
    })
      .then((response) => {
        return response as AccountCreatedResponse;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  getAccountByCode = async (accountCode: string) => {
    return await this.get(
      `v1/accounts/${accountCode}`,
      {},
      {
        Fields:
          "uuid,company_name,legal_address,legal_city,legal_zipcode,legal_country,legal_vat_number,balance,code",
      }
    )
      .then((response) => response as AccountData)
      .catch((error) => {
        throw new Error(error);
      });
  };

  updateAccount = async (
    code: string | undefined,
    data: AccountData | null
  ) => {
    if (!data || !code) throw new Error("No data provided");

    return await this.update(`v1/accounts/${code}`, {
      code: code,
      company_name: data.company_name,
      legal_address: data.legal_address,
      legal_city: data.legal_city,
      legal_zipcode: data.legal_zipcode,
      legal_country: data.legal_country,
      legal_vat_number: data.legal_vat_number,
    })
      .then((response) => {
        return response as AccountData;
      })
      .catch((error) => {
        toast.error(error.message);
        throw new Error(error);
      });
  };

  addRecipient = async (accountId: string, data: CreateAccountData | null) => {
    if (!data) throw new Error("No data provided");

    return await this.post("v1/recipients", {
      account: accountId,
      name: data.firstName + " " + data.lastName,
      email: data.email,
      receive_invoice: true,
      receive_payment_confirmation: true,
    })
      .then((response) => {
        return response as RecipientCreatedResponse;
      })
      .catch((error) => {
        toast.error(error.message);
        throw new Error(error);
      });
  };
}

export default AccountService;
