import paymentMethods from "../components/ui/PaymentMethods";
import { PaymentCardData } from "../store/reducers/paymentMethods/paymentMethodsSlice";
import useCardFormat from "./useCardFormat";
import useFormats from "./useFormats";

const usePaymentMethodFormat = () => {
  const { lastDigitsCardNumber, cardExpiry } = useCardFormat();
  const { sentenceCase } = useFormats();
  const description = (paymentMethod: PaymentCardData) =>
    paymentMethod.active ? cardExpiry(paymentMethod) : "Deactivated";

  const title = (paymentMethod: PaymentCardData) =>
    sentenceCase(paymentMethod.brand) +
    " ending with " +
    lastDigitsCardNumber(paymentMethod);

  return {
    description,
    title,
  };
};

export default usePaymentMethodFormat;
