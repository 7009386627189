import Popup from "../../layout/Popup";
import { HorizontalRule, PageTitle, Paragraph } from "../../ui/Fonts";
import { useEffect } from "react";
import {
  cancelSubscription,
  getSubscription,
  resetGetSubscriptionStatus,
} from "../../../store/reducers/subscriptions/subscriptionSlice";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ActionTile from "../../ui/ActionTile";
import useSubscriptionFormat from "../../../hooks/useSubscriptionFormat";
import ButtonGroup from "../../layout/ButtonGroup";
import Button from "../../ui/Button";
import LoadingStates from "../../ui/LoadingStates";
import ErrorMessage from "../../ui/ErrorMessage";

const CancelSubscription = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.subscriptions.getStatus);
  const deleteStatus = useAppSelector(
    (state) => state.subscriptions.deleteStatus
  );
  const error = useAppSelector((state) => state.subscriptions.error);
  const { subscriptionTitle, subscriptionDescription, subscriptionTag } =
    useSubscriptionFormat();
  const subscription = useAppSelector(
    (state) => state.subscriptions.subscription
  );

  useEffect(() => {
    if (status === "idle") {
      if (typeof uuid === "string") {
        dispatch<any>(getSubscription(uuid));
      }
    }

    return () => {
      if (status !== "idle") {
        dispatch(resetGetSubscriptionStatus());
      }
    };
  }, [dispatch, subscription, uuid]);

  useEffect(() => {
    if (deleteStatus === "succeeded") {
      dispatch(resetGetSubscriptionStatus());
      navigate("/");
    }
  }, [deleteStatus, dispatch]);

  const cancel = () => {
    if (typeof uuid === "string") {
      dispatch<any>(
        cancelSubscription({
          uuid,
          cancellation_type: "at_renewal",
        })
      );
    }
  };

  return (
    <Popup>
      <PageTitle>Cancel subscription</PageTitle>
      <Paragraph>Are you sure you want to cancel this subscription?</Paragraph>
      <HorizontalRule />
      {status === "loading" && <LoadingStates height={80} />}
      {subscription && status === "succeeded" && (
        <ActionTile
          title={subscriptionTitle(subscription)}
          description={subscriptionDescription(subscription)}
          tag={subscriptionTag(subscription)}
        />
      )}
      <ButtonGroup className={"justify-end"}>
        <Button
          disabled={status === "loading"}
          variant={"secondary"}
          href={"/"}
        >
          Cancel
        </Button>
        <Button
          variant={"danger"}
          onClick={() => cancel()}
          loading={deleteStatus === "loading"}
          disabled={deleteStatus === "loading" || status === "loading"}
        >
          Cancel subscription
        </Button>
      </ButtonGroup>
      {error && <ErrorMessage message={error} />}
    </Popup>
  );
};

export default CancelSubscription;
