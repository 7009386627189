import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SubscriptionService, {
  CreateSubscriptionBody,
  SubscriptionCancellationType,
  UpdateSubscriptionBody,
} from "../../../services/SubscriptionService";
import { IntervalType, Plan, PlanTerm, Status } from "../../../utils/types";

export type SubscriptionStatus =
  | "active"
  | "scheduled"
  | "not_renewing"
  | "not_starting"
  | "expired";

export interface SubscriptionData {
  uuid: string;
  current_mmr: number;
  next_renewal_date: string;
  last_renewal_date: string;
  quantity: number;
  plan: Plan;
  status: SubscriptionStatus;
  terms: PlanTerm;
}

interface SubscriptionState {
  subscriptions: SubscriptionData[] | null;
  subscription: any | null;
  status: Status;
  createStatus: Status;
  getStatus: Status;
  updateStatus: Status;
  deleteStatus: Status;
  error: string | null;
}

export interface EditSubscriptionData {
  uuid: string | null;
  subscription: UpdateSubscriptionBody | null;
}

/*

*/

const initialState: SubscriptionState = {
  subscriptions: null,
  subscription: null,
  status: "idle",
  createStatus: "idle",
  getStatus: "idle",
  updateStatus: "idle",
  deleteStatus: "idle",
  error: null,
};

export const fetchSubscriptions = createAsyncThunk(
  "subscriptions/fetchSubscriptions",
  async () => {
    console.log("fetchSubscriptions");
    return new SubscriptionService().getAllSubscriptions();
  }
);

export const getSubscription = createAsyncThunk(
  "subscriptions/getSubscription",
  async (subscriptionId: string) => {
    return new SubscriptionService().getSubscription(subscriptionId);
  }
);

export const newSubscription = createAsyncThunk(
  "subscriptions/newSubscription",
  async (data: CreateSubscriptionBody) => {
    return new SubscriptionService().newSubscription(data);
  }
);

export const editSubscription = createAsyncThunk(
  "subscriptions/editSubscription",
  async (data: EditSubscriptionData) => {
    return new SubscriptionService().updateSubscription(
      data.uuid,
      data.subscription
    );
  }
);

interface CancellationData {
  uuid: string;
  cancellation_type: SubscriptionCancellationType;
}

export const cancelSubscription = createAsyncThunk(
  "subscriptions/cancelSubscription",
  async (data: CancellationData) => {
    return new SubscriptionService().cancelSubscription(
      data.uuid,
      data.cancellation_type
    );
  }
);

const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    resetEditSubscriptionStatus(state) {
      state.updateStatus = "idle";
    },
    resetCreateSubscriptionStatus(state) {
      state.createStatus = "idle";
    },
    resetGetSubscriptionStatus(state) {
      state.getStatus = "idle";
      state.subscription = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSubscriptions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.subscriptions = action.payload;
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error?.message || null;
      })
      .addCase(newSubscription.pending, (state, action) => {
        state.createStatus = "loading";
      })
      .addCase(newSubscription.fulfilled, (state, action) => {
        state.createStatus = "succeeded";
        state.subscriptions = state.subscriptions
          ? [...state.subscriptions, action.payload]
          : [action.payload];
      })
      .addCase(newSubscription.rejected, (state, action) => {
        state.createStatus = "failed";
        state.error = action?.error?.message || null;
      })
      .addCase(getSubscription.pending, (state, action) => {
        state.getStatus = "loading";
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.getStatus = "succeeded";
        state.subscription = action.payload;
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.getStatus = "failed";
        state.error = action?.error?.message || null;
      })
      .addCase(editSubscription.pending, (state) => {
        state.updateStatus = "loading";
      })
      .addCase(editSubscription.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        state.subscription = action.payload;
        state.subscriptions = state.subscriptions
          ? state.subscriptions.map((subscription) => {
              if (subscription.uuid === action.payload.uuid) {
                return action.payload;
              }
              return subscription;
            })
          : [action.payload];
      })
      .addCase(editSubscription.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.error = action?.error?.message || null;
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.deleteStatus = "loading";
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.deleteStatus = "succeeded";
        state.subscription = action.payload;
        state.subscriptions = state.subscriptions
          ? state.subscriptions.map((subscription) => {
              if (subscription.uuid === action.payload.uuid) {
                return action.payload;
              }
              return subscription;
            })
          : [action.payload];
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.error = action?.error?.message || null;
      })
      .addDefaultCase((state, action) => {
        state.error = null;
      });
  },
});

export const subscriptionIsLoading = (state: any) =>
  state.subscriptions.status === "loading" ||
  state.subscriptions.createStatus === "loading" ||
  state.subscriptions.getStatus === "loading";

export default subscriptionSlice.reducer;
export const {
  resetEditSubscriptionStatus,
  resetCreateSubscriptionStatus,
  resetGetSubscriptionStatus,
} = subscriptionSlice.actions;
