import GuestLayout from "../../layout/GuestLayout";
import { HorizontalRule, PageTitle, Paragraph } from "../../ui/Fonts";
import Envelope from "../../ui/icons/Envelope";
import Button from "../../ui/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useSendAuthEmail from "../../../hooks/useSendAuthEmail";
import toast from "react-hot-toast";
import Toast from "../../ui/Toast";
import GoBackLink from "../../ui/GoBackLink";

const CheckEmailView = () => {
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get("email") || "";
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    return setEmail(emailParam);
  }, [emailParam]);

  const sendAuthEmail = useSendAuthEmail();

  const sendEmailAgain = async () => {
    await sendAuthEmail(email).then(() => {
      toast.custom((t) => <Toast message={"Email send to " + email} />, {
        duration: 2000,
      });
    });
  };

  return (
    <GuestLayout>
      <div className={"text-center"}>
        <HorizontalRule />
        <div className={"text-center space-y-4"}>
          <div className={"flex justify-center"}>
            <Envelope />
          </div>
          <PageTitle>Check your email</PageTitle>
          <Paragraph>
            We sent a access link to <br />
            <strong>{email}</strong>
          </Paragraph>
          {
            // todo open email client logic here
          }
          <div className={"py-4"}>
            <Button block={true} href={"mailto:"}>
              Open email app
            </Button>
          </div>
          <Paragraph>
            Didn’t receive the email?{" "}
            <button
              onClick={() => sendEmailAgain()}
              className={"font-medium text-gray-900"}
            >
              Click to resend
            </button>
          </Paragraph>
          <GoBackLink onClick={() => navigate("/login")}>
            Back to previous
          </GoBackLink>
        </div>
      </div>
    </GuestLayout>
  );
};

export default CheckEmailView;
