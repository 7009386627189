import { ReactNode } from "react";
import { SectionHeading } from "./Fonts";

interface Props {
  title: string;
  children?: ReactNode;
}

const StyleguideSection = ({ title, children }: Props) => {
  return (
    <div className={"w-full border border-black/20 p-6 rounded"}>
      <SectionHeading>{title}</SectionHeading>
      {children}
    </div>
  );
};

export default StyleguideSection;
