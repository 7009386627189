import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PlanService from "../../../services/PlanService";
import { RootState } from "../../index";
import {
  IntervalType,
  Plan,
  PlanTerm,
  SubscriptionOption,
} from "../../../utils/types";

interface PlanStateType {
  plans: SubscriptionOption[];
  plan: SubscriptionOption | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  currency: string | null;
  showCurrencySelector: boolean;
}

const initialState: PlanStateType = {
  plans: [],
  plan: null,
  status: "idle",
  error: null,
  currency: null,
  showCurrencySelector: false,
};

export const fetchPlans = createAsyncThunk("plans/fetchPlans", async () => {
  return await new PlanService().fetchPlans();
});

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    showCurrencySelector(state, action) {
      state.showCurrencySelector = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlans.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched plans to the array
        state.plans = state.plans.concat(action.payload) as any;
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error?.message || null;
      });
  },
});

export const { setCurrency, showCurrencySelector } = plansSlice.actions as any;

export const getAllPlans = (state: RootState) =>
  state.plans.plans.flatMap((plan: any) => plan) as Plan[];

export const getPlanByInterval =
  (state: RootState) => (interval: IntervalType) => {
    let planTerms = getAllPlanTerms(state).filter((planTerm: PlanTerm) => {
      return planTerm.interval_type === interval;
    });

    // if (planTermsHasMultipleCurrencies(state) && state.plans.currency) {
    //   planTerms = planTerms.filter((planTerm: PlanTerm) => {
    //     return planTerm.currency === state.plans.currency;
    //   });
    // }

    let plans = getAllPlans(state).filter((plan: Plan) => {
      // return plan if it has a planTerm with the given interval
      return planTerms.some((planTerm: PlanTerm) => {
        return plan.planterms_set?.includes(planTerm);
      });
    });

    plans = plans.map((plan: Plan) => {
      return {
        ...plan,
        planterms_set: plan.planterms_set
          .filter((planTerm: PlanTerm) => {
            // both filter by planTerm.interval_type === interval and planTerm.currency === state.plans.currency
            if (planTermsHasMultipleCurrencies(state) && state.plans.currency) {
              return (
                planTerm.interval_type === interval &&
                planTerm.currency === state.plans.currency
              );
            } else {
              return planTerm.interval_type === interval;
            }
          })
          // sort by price ascending, wrap price with parseFloat to convert to number
          .sort((a: PlanTerm, b: PlanTerm) => {
            return parseFloat(a.price) - parseFloat(b.price);
          }),
        // return the first planTerm
      };
    });

    // remove empty

    return plans;
  };

export const getAllPlanTerms = (state: RootState) =>
  getAllPlans(state)
    .map((plan: any) => plan.planterms_set)
    .flatMap((planTerms: any) => planTerms) as PlanTerm[];

export const getPlanByUuid = (state: RootState) => (uuid: string) =>
  getAllPlans(state).find((plan: Plan) => plan.uuid === uuid);

export const getPlanTermsByUuid = (state: RootState) => (uuid: string) =>
  getAllPlanTerms(state).filter((planTerm: PlanTerm) => {
    return planTerm.uuid === uuid;
  });

export const getPlanTermByUuid = (state: RootState) => (uuid: string) => {
  const planTerms = getAllPlanTerms(state);

  return planTerms.find((planTerm: PlanTerm) => {
    return planTerm.uuid === uuid;
  });
};

export const getPlanTermByIntervalAndPlanUuid =
  (state: RootState) => (interval: IntervalType, planUuid: string) => {
    const planTerms = getPlanTermsByUuid(state)(planUuid);

    // if (planTermsHasMultipleCurrencies(state) && state.plans.currency) {
    //   return planTerms.filter((planTerm: PlanTerm) => {
    //     return planTerm.currency === state.plans.currency;
    //   });
    // }

    return planTerms.filter((planTerm: PlanTerm) => {
      return planTerm.interval_type === interval;
    });
  };

export const getPlanTermsByCurrency = (state: RootState) =>
  getAllPlanTerms(state).filter((planTerm: PlanTerm) => {
    return planTerm.currency === state.plans.currency;
  });

export const getPlanTermIntervals = (state: RootState) => {
  // get unique interval_type values
  const planTerms = planTermsHasMultipleCurrencies(state)
    ? getPlanTermsByCurrency(state)
    : getAllPlanTerms(state);

  const intervals = planTerms
    .map((planTerm: any) => planTerm.interval_type)
    .filter(
      (interval: any, index: any, self: any) => self.indexOf(interval) === index
    );

  return intervals;
};

export const getPlanTermCurrencies = (state: RootState) => {
  // get unique currency values
  const currencies = getAllPlanTerms(state)
    .map((planTerm: any) => planTerm.currency)
    .filter(
      (currency: any, index: any, self: any) => self.indexOf(currency) === index
    );

  return currencies;
};
export const planTermsHasMultipleCurrencies = (state: RootState) => {
  const currencies = getAllPlanTerms(state)
    .map((planTerm: any) => planTerm.currency)
    .filter(
      (currency: any, index: any, self: any) => self.indexOf(currency) === index
    );

  return currencies.length > 1;
};

export const getStatus = (state: RootState) => state.plans.status;
export const isLoading = (state: RootState) => state.plans.status === "loading";

export default plansSlice.reducer;
