import FenerumAPIService from "./FenerumAPIService";
import toast from "react-hot-toast";

export interface CreateSubscriptionBody {
  account: string;
  terms: string;
  collection_method: string;
  quantity: number;
  start_date?: string;
  end_date?: string;
}

export interface UpdateSubscriptionBody {
  terms: string;
  collection_method?: string;
  quantity: number;
  change_type: "immediate" | "at_specified_time" | "at_renewal";
}

export type SubscriptionCancellationType =
  | "full_refund"
  | "at_renewal"
  | "today"
  | "cancel_invalid";

class SubscriptionService extends FenerumAPIService {
  async getAllSubscriptions() {
    return await this.get("v1/subscriptions")
      .then((response) => response.results)
      .catch((error) => {
        throw error;
      });
  }

  async getSubscription(uuid: string) {
    return await this.get(`v1/subscriptions/${uuid}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }

  async updateSubscription(
    uuid: string | null,
    data: UpdateSubscriptionBody | null
  ) {
    return await this.update(`v1/subscriptions/${uuid}`, data)
      .then((response) => response)
      .catch((error) => {
        toast.error(error.message);
        throw new Error("Could not update subscription");
      });
  }

  async cancelSubscription(
    uuid: string,
    cancellation_type: SubscriptionCancellationType
  ) {
    return await this.post(`v1/subscriptions/${uuid}/cancel`, {
      cancellation_type,
    })
      .then((response) => response)
      .catch((error) => {
        toast.error(error.message);
        throw error;
      });
  }

  async newSubscription(data: CreateSubscriptionBody) {
    return await this.post("v1/subscriptions", data)
      .then((response) => response)
      .catch((error) => {
        toast.error(error.message);
        throw error;
      });
  }
}

export default SubscriptionService;
