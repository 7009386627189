const DefaultCard = () => {
  return (
    <svg
      width="28"
      height="19"
      viewBox="0 0 28 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="19" rx="1.9" fill="#222222" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46904 4.97279C8.16959 4.97279 7.92683 5.22758 7.92683 5.54189V13.4581C7.92683 13.7724 8.16959 14.0272 8.46904 14.0272H19.531C19.8304 14.0272 20.0732 13.7724 20.0732 13.4581V5.54189C20.0732 5.22758 19.8304 4.97279 19.531 4.97279H8.46904ZM7 5.54189C7 4.69033 7.65771 4 8.46904 4H19.531C20.3423 4 21 4.69033 21 5.54189V13.4581C21 14.3097 20.3423 15 19.531 15H8.46904C7.65771 15 7 14.3097 7 13.4581V5.54189Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8.5C7 8.22386 7.20748 8 7.46341 8H20.5366C20.7925 8 21 8.22386 21 8.5C21 8.77614 20.7925 9 20.5366 9H7.46341C7.20748 9 7 8.77614 7 8.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 12.5C16.5 12.2239 16.7239 12 17 12H18.6275C18.9037 12 19.1275 12.2239 19.1275 12.5C19.1275 12.7761 18.9037 13 18.6275 13H17C16.7239 13 16.5 12.7761 16.5 12.5Z"
        fill="white"
      />
    </svg>
  );
};

export default DefaultCard;
