interface IconProps {
  className?: string;
}

const PencilIcon = (props: IconProps) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.92829 10.6172L1.39258 11.6072L2.38258 8.07147L8.85686 1.62861C8.93004 1.55378 9.01743 1.49432 9.1139 1.45372C9.21037 1.41312 9.31398 1.39221 9.41865 1.39221C9.52332 1.39221 9.62693 1.41312 9.7234 1.45372C9.81987 1.49432 9.90726 1.55378 9.98044 1.62861L11.3711 3.02719C11.4448 3.10023 11.5032 3.18713 11.5431 3.28288C11.583 3.37862 11.6036 3.48132 11.6036 3.58504C11.6036 3.68877 11.583 3.79146 11.5431 3.88721C11.5032 3.98296 11.4448 4.06986 11.3711 4.1429L4.92829 10.6172Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PencilIcon;
