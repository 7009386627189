import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SetPlansForm from "../../layout/forms/SetPlansForm";
import CreateAccountForm from "../../layout/forms/CreateAccountForm";
import GoBackLink from "../../ui/GoBackLink";
import CheckoutBox from "../../ui/CheckoutBox";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hasAccount } from "../../../store/reducers/auth/authSlice";
import { PlanTerm } from "../../../utils/types";
import { AccountStep } from "../../../store/reducers/checkout/checkoutSlice";
import { Paragraph, SectionHeading } from "../../ui/Fonts";
import { planTermsHasMultipleCurrencies } from "../../../store/reducers/plans/plansSlice";

interface Props {
  step?: AccountStep;
}

const CreateAccountView = ({ step = "plans" }: Props) => {
  const accountIsReady = useAppSelector(hasAccount);
  const accountStep = useAppSelector((state) => state.checkout.checkoutStep);
  const navigate = useNavigate();

  if (accountIsReady) {
    return <Navigate to={"/"} />;
  }

  return (
    <div>
      {step === "create-account" && (
        <div className={"mb-6"}>
          <GoBackLink onClick={() => navigate("/create-account")} />
        </div>
      )}
      {accountStep !== "submitting" && (
        <div
          className={
            "flex flex-wrap justify-between space-y-4 md:space-y-0 md:-mx-5"
          }
        >
          <div className={"w-full md:w-1/2 md:px-5"}>
            {step === "plans" && <SetPlansForm step={step} />}
            {step === "create-account" && <CreateAccountForm />}
          </div>
          <div className={"w-full md:w-1/2 md:px-5"}>
            <CheckoutBox step={step} />
          </div>
        </div>
      )}
      {accountStep === "submitting" && (
        <div className={"flex flex-col items-center justify-center"}>
          <SectionHeading>Creating your account...</SectionHeading>
          <Paragraph>Please wait while we create your account.</Paragraph>
          <div className={"w-1/2"}>
            <div className={"flex justify-center mt-12"}>
              <div className={"w-1/2 text-center"}>
                <div
                  className={
                    "animate-spin inline-block rounded-full h-16 w-16 border-b-2 border-gray-900"
                  }
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateAccountView;
