import GuestLayout from "../layout/GuestLayout";
import Button from "../ui/Button";
import ButtonGroup from "../layout/ButtonGroup";
import Tag from "../ui/Tag";
import { PageTitle, Paragraph, SectionHeading } from "../ui/Fonts";
import StyleguideSection from "../ui/StyleguideSection";
import Tabs from "../ui/Tabs";
import InputField from "../ui/InputField";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import SelectField from "../ui/SelectField";
import ActionPanel from "../ui/ActionPanel";
import PaymentCardForm from "../layout/forms/PaymentCardForm";
import RadioField from "../ui/RadioField";

const StyleGuide = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const methods = useFormContext();

  return (
    <GuestLayout disableMiddleware={true}>
      <div className={"flex flex-col items-center space-y-8"}>
        <StyleguideSection title={"Tags"}>
          <div className={"flex space-x-2"}>
            <Tag color={"green"}>Success</Tag>
            <Tag color={"red"}>Error</Tag>
            <Tag color={"yellow"}>Notice</Tag>
          </div>
        </StyleguideSection>
        <StyleguideSection title={"Buttons"}>
          <ButtonGroup>
            <Button>Primary</Button>
            <Button variant={"secondary"}>Secondary</Button>
          </ButtonGroup>
        </StyleguideSection>
        <StyleguideSection title={"Form elements"}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(() => console.log("Valid"))}>
              <InputField
                placeholder={"Write stuff here..."}
                required={true}
                label={"Regular input"}
                register={register}
                name={"example1"}
                errors={errors}
              />
              <InputField
                placeholder={"Write stuff here..."}
                required={true}
                label={"Regular input with error"}
                register={register}
                name={"example2"}
                errors={{
                  example2: { message: "This is a custom error message" },
                }}
              />
              <SelectField
                placeholder={"Select something..."}
                required={true}
                label={"Select"}
                register={register}
                name={"example3"}
                errors={errors}
                options={[
                  { label: "Option 1", value: "option1" },
                  { label: "Option 2", value: "option2" },
                  { label: "Option 3", value: "option3" },
                ]}
              />
              <RadioField value={"option-1"} name={"test"}>
                <Paragraph>Option 1</Paragraph>
              </RadioField>
              <RadioField value={"option-2"} name={"test"}>
                <Paragraph>Option 2</Paragraph>
              </RadioField>
            </form>
          </FormProvider>
          <PaymentCardForm />
        </StyleguideSection>
        <StyleguideSection title={"Fonts"}>
          <PageTitle>Page title</PageTitle>
          <SectionHeading>Section heading</SectionHeading>
          <Paragraph>
            Paragraph: Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco
          </Paragraph>
        </StyleguideSection>
        <StyleguideSection title={"Tabs"}>
          <Tabs
            tabs={[
              { label: "Tab 1", content: "Tab 1 content" },
              { label: "Tab 2", content: "Tab 2 content" },
            ]}
          ></Tabs>
        </StyleguideSection>
        <StyleguideSection title={"Action panel"}>
          <ActionPanel
            title={"Section title"}
            description={
              "Section description ut enim ad minim veniam, quis nostrud exercitation ullamco"
            }
            button={<Button variant={"secondary"}>Action button</Button>}
          />
        </StyleguideSection>
      </div>
    </GuestLayout>
  );
};

export default StyleGuide;
