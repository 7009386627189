import React, { ReactNode } from "react";
import clsx from "clsx";
import { ButtonVariant } from "../../utils/types";
import { Link } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
  variant?: ButtonVariant;
  href?: string;
  onClick?: () => void;
  block?: boolean;
  disabled?: boolean;
  icon?: ReactNode | null;
  loading?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
}

const Button = ({
  children,
  icon = null,
  loading = false,
  type = undefined,
  disabled = false,
  variant = "primary",
  block = false,
  href = "",
  onClick = () => {},
}: Props) => {
  if (!href) {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        type={type}
        className={clsx(
          "inline-flex justify-center items-center border rounded-md py-2 px-4 transition duration-300 ease-in-out disabled:opacity-50 disabled:bg-gray-900 disabled:text-white disabled:cursor-not-allowed",
          variant === "primary" &&
            "border-gray-900 bg-gray-900 text-white hover:bg-transparent hover:text-gray-900",
          variant === "secondary" &&
            "border-gray-900 bg-transparent text-gray-900 hover:bg-gray-900 hover:text-white",
          variant === "danger" &&
            "border-red-600 bg-red-600 text-white hover:bg-red-900 hover:text-white hover:border-red-900",
          block && "w-full"
        )}
      >
        {!loading && <span>{children}</span>}
        {loading && <span>Please wait...</span>}
        {icon && <span className={"ml-2"}>{icon}</span>}
      </button>
    );
  } else {
    return (
      <Link
        to={href}
        className={clsx(
          "inline-flex justify-center text-center items-center border rounded-md py-2 px-4 transition duration-300 ease-in-out disabled:opacity-25 disabled:bg-gray-900 disabled:text-white disabled:cursor-not-allowed",
          variant === "primary" &&
            "border-gray-900 bg-gray-900 text-white hover:bg-transparent hover:text-gray-900",
          variant === "secondary" &&
            "border-gray-900 bg-transparent text-gray-900 hover:bg-gray-900 hover:text-white",
          block && "w-full",
          disabled && "pointer-events-none opacity-25"
        )}
      >
        {!loading && <span>{children}</span>}
        {loading && <span>Please wait...</span>}
        {icon && <span className={"ml-2"}>{icon}</span>}
      </Link>
    );
  }
};

export default Button;
