import FenerumAPIService from "./FenerumAPIService";

class PlanService extends FenerumAPIService {
  async fetchPlans() {
    return await this.get("v1/plans").then((response) => {
      console.log(response.results);
      return response?.results;
    });
  }
}

export default PlanService;
