interface IconProps {
  className?: string;
}

const TrashIcon = (props: IconProps) => {
  return (
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      className={props.className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.889 9.38342C15.1995 9.06428 15.6248 8.88133 16.0725 8.88133H19.9292C20.3769 8.88133 20.8022 9.06428 21.1127 9.38342C21.4226 9.70192 21.5934 10.1299 21.5934 10.5723V12.8452H24.7431C24.7473 12.8451 24.7515 12.8451 24.7558 12.8452H25.7143C26.1009 12.8452 26.4143 13.1586 26.4143 13.5452C26.4143 13.9318 26.1009 14.2452 25.7143 14.2452H25.4032L24.6125 25.6258C24.6125 25.6259 24.6125 25.6258 24.6125 25.6258C24.566 26.2968 24.2737 26.9288 23.789 27.3928C23.3036 27.8573 22.6613 28.1187 21.9906 28.1187H14.0111C13.3404 28.1187 12.6981 27.8573 12.2127 27.3928C11.728 26.9288 11.4357 26.2969 11.3892 25.6258L10.5985 14.2452H10.2874C9.9008 14.2452 9.5874 13.9318 9.5874 13.5452C9.5874 13.1586 9.9008 12.8452 10.2874 12.8452H11.2459C11.2502 12.8451 11.2544 12.8451 11.2587 12.8452H14.4083V10.5723C14.4083 10.1299 14.5791 9.70192 14.889 9.38342ZM12.0019 14.2452L12.7858 25.529C12.8086 25.858 12.9517 26.1622 13.1808 26.3814C13.4093 26.6002 13.7063 26.7187 14.0111 26.7187H21.9906C22.2954 26.7187 22.5924 26.6002 22.8209 26.3814C23.05 26.1622 23.1931 25.8581 23.2158 25.5291L23.9998 14.2452H12.0019ZM20.1934 12.8452H15.8083V10.5723C15.8083 10.489 15.8407 10.4129 15.8924 10.3597C15.9435 10.3072 16.0087 10.2813 16.0725 10.2813H19.9292C19.993 10.2813 20.0582 10.3072 20.1093 10.3597C20.161 10.4129 20.1934 10.489 20.1934 10.5723V12.8452ZM16.0725 16.809C16.4591 16.809 16.7725 17.1224 16.7725 17.509V23.4548C16.7725 23.8414 16.4591 24.1548 16.0725 24.1548C15.6859 24.1548 15.3725 23.8414 15.3725 23.4548V17.509C15.3725 17.1224 15.6859 16.809 16.0725 16.809ZM19.9292 16.809C20.3158 16.809 20.6292 17.1224 20.6292 17.509V23.4548C20.6292 23.8414 20.3158 24.1548 19.9292 24.1548C19.5426 24.1548 19.2292 23.8414 19.2292 23.4548V17.509C19.2292 17.1224 19.5426 16.809 19.9292 16.809Z"
        fill="#222222"
      />
    </svg>
  );
};

export default TrashIcon;
