const useValidationRegex = () => {
  //const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const emailRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const onlyNumbersRegex = /^[0-9]*$/g;

  return {
    emailRegex,
    onlyNumbersRegex,
  };
};

export default useValidationRegex;
