import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import toast from "react-hot-toast";

const toastIfServerError = (error: AxiosError) => {
  /**
   * This function creates a toast on server errors 5**.
   * This will let the user know that something unintended happened.
   */
  const statusCode: any = error.response?.status;
  console.log(error)
  if (statusCode && statusCode >= 500) {
    toast.error("Something went wrong. Please try again or contact support.");
  }
}

const getFenerumError = (error: AxiosError) => {
  /**
   * This function tries to return error message from Fenerum API.
   * First it tries to return value under those keys: "message", "error" and "detail".
   * If it fails it will return value for the first key that it finds.
   * It will most likely fall back to this behaviour when validation for field fails.
   * In this case the dictionary key is field name.
   * If it fails to recognize error it will not return anything.
   */
  const data: any = error.response?.data;
  if (data) {
    if (typeof data === 'object'){
      if (data.hasOwnProperty("message")) {
        return data["message"];
      }
      if (data.hasOwnProperty("error")) {
        return data["error"];
      }
      if (data.hasOwnProperty("detail")) {
        return data["detail"];
      }
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          return data[key];
        }
      }
    }
  }
}

class FenerumAPIService {
  private readonly apiURL: string;
  private api: AxiosInstance;

  constructor() {
    this.apiURL = process.env.REACT_APP_API_URL ?? "";

    const tokenFromStorage = localStorage.getItem("token");
    const token =
      tokenFromStorage && tokenFromStorage !== "null" ? tokenFromStorage : null;
    const tokenWithoutQuotes = token ? token?.slice(1, -1) : null;

    this.api = axios.create({
      baseURL: this.apiURL,
      headers: {
        "Content-Type": "application/json",
        ...(tokenWithoutQuotes && {
          Authorization: `Self-Service-Token ${tokenWithoutQuotes}`,
        }),
      },
    });

    this.api.interceptors.response.use(function (response) {
      // Refresh token from header HTTP_FENERUM_REFRESHED_TOKEN
      if (response.headers["fenerum-refreshed-token"]) {
        localStorage.setItem(
          "token",
          `"${response.headers["fenerum-refreshed-token"]}"`
        );
      }
      return response;
    });
  }

  async get(path: string, body: any = {}, headers: any = {}) {
    const url = `/api/${path}`;
    return await this.api
      .get(url, {
        params: body,
        headers: headers,
      })
      .then((response: AxiosResponse<any>) => {
        return response.data as any;
      })
      .catch((error) => {
        toastIfServerError(error);
        const fenerumError = getFenerumError(error);
        if (fenerumError) {
          throw new Error(fenerumError);
        } else {
          throw new Error(error);
        }
      });
  }

  async post(path: string, body: any = {}) {
    const url = `/api/${path}`;
    console.log(url, body);
    return await this.api
      .post(url, body)
      .then((response: AxiosResponse<any>) => {
        // TODO: Request retrying
        return response.data as any;
      })
      .catch((error) => {
        toastIfServerError(error);
        const fenerumError = getFenerumError(error);
        if (fenerumError) {
          throw new Error(fenerumError);
        } else {
          throw new Error(error);
        }
      });
  }

  async update(path: string, body: any = {}) {
    const url = `/api/${path}`;

    return await this.api
      .put(url, body)
      .then((response: AxiosResponse<any>) => {
        // TODO: Request retrying
        return response.data as any;
      })
      .catch((error) => {
        toastIfServerError(error);
        const fenerumError = getFenerumError(error);
        if (fenerumError) {
          throw new Error(fenerumError);
        } else {
          throw new Error(error);
        }
      });
  }
}

export default FenerumAPIService;
