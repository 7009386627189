import { ChangeEvent, ReactNode } from "react";
import clsx from "clsx";

interface RadioProps {
  onChange?: (value?: any) => void;
  name: string;
  isSelected?: boolean;
  value: any;
  required?: boolean;
  rules?: any;
  children?: ReactNode;
}

const RadioField = ({
  onChange = () => {},
  name,
  value,
  isSelected = false,
  required = false,
  rules = {},
  children,
}: RadioProps) => {
  return (
    <label
      className={clsx(
        "border border-gray-300 p-4 rounded-md w-full flex cursor-pointer hover:border-gray-900 peer-checked:border-gray-900 transition-all mb-4",
        isSelected && "border-gray-900"
      )}
    >
      <div>
        <input
          value={value}
          type="radio"
          checked={isSelected}
          onChange={(e) => onChange(e.target.value)}
          className={"absolute hidden peer"}
        />
        <div
          className={
            "border border-gray-300 rounded-full w-5 h-5 shrink-0 flex items-center justify-center mr-4 peer-checked:border-gray-900 peer-checked:border-gray-900"
          }
        >
          {isSelected && (
            <div
              className={"w-3 h-3 rounded-full transition-all bg-gray-900"}
            ></div>
          )}
        </div>
      </div>
      <div className={"w-full"}>{children}</div>
    </label>
  );
};

export default RadioField;
