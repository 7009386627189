import FenerumAPIService from "./FenerumAPIService";
import { useAuth } from "../hooks/useAuth";

class PaymentService extends FenerumAPIService {
  initiatePayment = async (paymentToken: string | undefined) => {
    const { organizationSlug } = useAuth();
    return await this.post(`self-service/${organizationSlug}/initiate-payment/`, {
      payment_token: paymentToken,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new Error(error.response?.data?.detail || error);
      });
  };
}

export default PaymentService;
