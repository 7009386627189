import Popup from "../../layout/Popup";
import { HorizontalRule, PageTitle, Paragraph } from "../../ui/Fonts";
import LoadingStates from "../../ui/LoadingStates";
import ActionTile from "../../ui/ActionTile";
import ButtonGroup from "../../layout/ButtonGroup";
import Button from "../../ui/Button";
import ErrorMessage from "../../ui/ErrorMessage";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import React, { useEffect } from "react";
import {
  disablePaymentCard,
  fetchPaymentCards,
  getPaymentCard,
  resetPaymentMethod,
} from "../../../store/reducers/paymentMethods/paymentMethodsSlice";
import { useNavigate, useParams } from "react-router-dom";
import PaymentCard from "../../ui/icons/PaymentCard";
import usePaymentMethodFormat from "../../../hooks/usePaymentMethodFormat";

const DisablePaymentCard = () => {
  const status = useAppSelector((state) => state.paymentMethods.updateStatus);
  const error = useAppSelector((state) => state.paymentMethods.error);
  const getStatus = useAppSelector((state) => state.paymentMethods.getStatus);
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { description, title } = usePaymentMethodFormat();
  const paymentMethod = useAppSelector(
    (state) => state.paymentMethods.paymentMethod
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!paymentMethod && typeof uuid === "string") {
      dispatch<any>(getPaymentCard(uuid));
    }
    return () => {
      if (paymentMethod) {
        dispatch<any>(resetPaymentMethod());
        dispatch<any>(fetchPaymentCards()); // Required to update 'active'-attr to false when disabling
      }
    };
  }, [paymentMethod]);

  useEffect(() => {
    if (status === "succeeded") {
      navigate("/");
    }
  }, [status]);

  const disable = () => {
    if (typeof uuid === "string") {
      dispatch<any>(disablePaymentCard(uuid));
    }
  };
  return (
    <Popup>
      <PageTitle>Disable card</PageTitle>
      <Paragraph>
        Are you sure you want to disable this payment card? This cannot be
        reverted.
      </Paragraph>
      <HorizontalRule />
      {getStatus === "loading" && <LoadingStates height={80} />}
      {paymentMethod && (
        <ActionTile
          key={paymentMethod.uuid}
          icon={<PaymentCard name={paymentMethod.brand} />}
          description={description(paymentMethod)}
          descriptionColor={paymentMethod.active ? null : "red"}
          title={title(paymentMethod)}
        />
      )}
      <ButtonGroup className={"justify-end"}>
        <Button
          disabled={getStatus === "loading"}
          variant={"secondary"}
          href={"/"}
        >
          Cancel
        </Button>
        <Button
          onClick={() => disable()}
          variant={"danger"}
          loading={status === "loading"}
          disabled={status === "loading" || getStatus === "loading"}
        >
          Disable card
        </Button>
      </ButtonGroup>
      {error && <ErrorMessage message={error} />}
    </Popup>
  );
};

export default DisablePaymentCard;
