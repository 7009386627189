import moment from "moment";

const useDateFormat = () => {
  const formatDate = (date: string) => {
    return moment(date).format("DD MMM. YYYY");
  };

  return { formatDate };
};

export default useDateFormat;
