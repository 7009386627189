import { ReactElement } from "react";
import { Paragraph, SectionHeading } from "./Fonts";
import { ButtonVariant } from "../../utils/types";

interface Props {
  title: string;
  description?: string;
  button?: ReactElement<ButtonVariant> | null;
}

const ActionPanel = ({ title, description, button }: Props) => {
  return (
    <div className={"flex justify-between space-x-6 mb-4"}>
      <div>
        <SectionHeading className={"!mb-2"}>{title}</SectionHeading>
        {description && <Paragraph>{description}</Paragraph>}
      </div>
      {button && <div className={"shrink-0"}>{button}</div>}
    </div>
  );
};

export default ActionPanel;
