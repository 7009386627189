const AmexCard = () => {
  return (
    <svg
      width="28"
      height="19"
      viewBox="0 0 28 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="19" rx="1.9" fill="#016FD0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.70859 7L2 12.9923H5.24257L5.64455 12.0369H6.5634L6.96538 12.9923H10.5345V12.2631L10.8526 12.9923H12.6988L13.0169 12.2477V12.9923H20.4397L21.3423 12.0617L22.1875 12.9923L26 13L23.2828 10.0129L26 7H22.2466L21.368 7.91341L20.5494 7H12.4743L11.7809 8.54666L11.0712 7H7.83544V7.7044L7.47548 7H4.70859ZM15.7931 7.85092H20.0557L21.3594 9.25877L22.7052 7.85092H24.0089L22.028 10.012L24.0089 12.1482H22.646L21.3423 10.724L19.9897 12.1482H15.7931V7.85092ZM16.8458 9.52613V8.74044H19.5055L20.666 9.99576L19.454 11.2579H16.8458V10.401H19.1712V9.52613H16.8458ZM5.33601 7.85092H6.91659L8.7132 11.9143V7.85092H10.4447L11.8323 10.7644L13.1112 7.85092H14.8341V12.1508H13.7858L13.7772 8.78143L12.2489 12.1508H11.3112L9.77429 8.78143V12.1508H7.61771L7.20887 11.1868H5.00003L4.59203 12.1499H3.43656L5.33601 7.85092ZM5.37719 10.2957L6.10491 8.57843L6.83179 10.2957H5.37719Z"
        fill="white"
      />
    </svg>
  );
};

export default AmexCard;
