import GuestLayout from "../../layout/GuestLayout";
import React from "react";
import { HorizontalRule, PageTitle } from "../../ui/Fonts";
import LoginForm from "../../layout/forms/LoginForm";
import useSendAuthEmail from "../../../hooks/useSendAuthEmail";

const LoginView = () => {
  return (
    <GuestLayout>
      <div className={"text-center"}>
        <HorizontalRule />
        <LoginForm />
      </div>
    </GuestLayout>
  );
};

export default LoginView;
