import DownloadIcon from "./icons/DownloadIcon";
import ActionPanel from "./ActionPanel";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  clearFile,
  downloadInvoice,
  fetchInvoices,
  InvoiceData,
  setDownloadUuid,
} from "../../store/reducers/billingHistory/billingHistorySlice";
import LoadingStates from "./LoadingStates";
import clsx from "clsx";
import { Paragraph, Subheading } from "./Fonts";
import { useMoneyFormat } from "../../hooks/useMoneyFormat";
import useFormats from "../../hooks/useFormats";
import { Link } from "react-router-dom";
import { CreditCardIcon } from "@heroicons/react/24/outline";

const BillingHistory = () => {
  const dispatch = useAppDispatch();
  const invoices = useAppSelector((state) => state.billingHistory.invoices);
  const status = useAppSelector((state) => state.billingHistory.status);
  const info = useAppSelector((state) => state.auth.info);
  const downloadStatus = useAppSelector(
    (state) => state.billingHistory.downloadStatus
  );
  const downloadingUuid = useAppSelector(
    (state) => state.billingHistory.downloadingUuid
  );
  const downloadFile = useAppSelector(
    (state) => state.billingHistory.downloadFile
  );

  const { formatMoney } = useMoneyFormat();
  const { sentenceCase } = useFormats();

  useEffect(() => {
    if (!invoices) {
      dispatch<any>(fetchInvoices());
    }
  }, [dispatch, invoices]);

  useEffect(() => {
    if (downloadFile) {
      // downloadfile is a base64 encoded string
      const url = `data:application/pdf;base64,${downloadFile}`;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${info?.code}-${downloadingUuid?.invoice_number}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      // cleanup
      document.body.removeChild(link);
      dispatch(setDownloadUuid(null));
      dispatch(clearFile());
    }
  }, [downloadFile]);

  const downloadInvoiceHandler = (invoice: InvoiceData) => {
    dispatch(setDownloadUuid(invoice));
    dispatch<any>(downloadInvoice(invoice));
  };

  return (
    <>
      <ActionPanel
        title={"Billing history"}
        description={"View billing history and invoices"}
      />
      {status === "loading" && <LoadingStates count={5} height={30} />}
      {status === "succeeded" && (
        <div className={"border border-gray-300 w-full rounded-lg"}>
          <table
            className={
              "min-w-full table-fixed divide-y rounded-lg overflow-hidden divide-gray-300 "
            }
          >
            <thead className={"bg-gray-100"}>
              <tr>
                {/*<th scope="col" className="relative px-7 sm:w-12 sm:px-6">*/}
                {/*  /!*<input*!/*/}
                {/*  /!*  type="checkbox"*!/*/}
                {/*  /!*  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"*!/*/}
                {/*  /!*  ref={checkbox}*!/*/}
                {/*  /!*  checked={checked}*!/*/}
                {/*  /!*  onChange={toggleAll}*!/*/}
                {/*  /!*/
                /*/}
                {/*</th>*/}
                <th scope="col" className="min-w-[12rem] py-3.5 px-3 text-left">
                  <Subheading className={"!mb-0"}>Invoice</Subheading>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left">
                  <Subheading className={"!mb-0"}>Billing date</Subheading>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left">
                  <Subheading className={"!mb-0"}>Amount</Subheading>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left">
                  <Subheading className={"!mb-0"}>Status</Subheading>
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                ></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300 bg-white">
              {invoices && invoices.length > 0 ? (
                invoices.map((invoice) => (
                  <tr key={invoice.uuid} className={""}>
                    {/*<td className="relative px-7 sm:w-12 sm:px-6">*/}
                    {/*  /!*{selectedPeople.includes(person) && (*!/*/}
                    {/*  /!*  <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />*!/*/}
                    {/*  /!*)}*!/*/}
                    {/*</td>*/}
                    <td className={clsx("whitespace-nowrap py-4 px-3")}>
                      <Paragraph className={"!mb-0 text-gray-500"}>
                        Invoice#{invoice.invoice_number} - {invoice.date}
                      </Paragraph>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      <Paragraph className={"!mb-0 text-gray-500"}>
                        {invoice.due_date}
                      </Paragraph>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      <Paragraph className={"!mb-0 text-gray-500"}>
                        {formatMoney(invoice.total, invoice.currency)}
                      </Paragraph>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-3 pr-4">
                      <Paragraph
                        className={"!mb-0 flex items-center text-gray-500"}
                      >
                        <span
                          className={clsx(
                            "w-2 h-2 inline-block rounded-full mr-1.5",
                            invoice.status === "paid" && "bg-green-500",
                            invoice.status === "due" && "bg-red-500",
                            invoice.status === "open" && "bg-yellow-500",
                            invoice.status === "nonpayment" && "bg-blue-500",
                            invoice.status === "contested" && "bg-blue-500",
                            invoice.status === "chargeback" && "bg-blue-500",
                            invoice.status === "debt_collection" &&
                              "bg-blue-500"
                          )}
                        ></span>
                        <span>{sentenceCase(invoice.status)}</span>
                      </Paragraph>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-3 pr-4 flex justify-end">
                      {invoice.payment_link && (
                        <Link to={invoice.payment_link}>
                          <button
                            className={clsx(
                              "flex items-center",
                              invoice.has_pdf && "mr-10"
                            )}
                          >
                            <CreditCardIcon className={"mr-2 w-4 h-4"} />
                            Pay now
                          </button>
                        </Link>
                      )}
                      {invoice.has_pdf && (
                        <button
                          onClick={() => downloadInvoiceHandler(invoice)}
                          className={"flex items-center"}
                          disabled={
                            downloadStatus === "loading" &&
                            downloadingUuid?.uuid === invoice.uuid
                          }
                        >
                          <DownloadIcon className={"mr-2 w-4 h-4"} />
                          {downloadStatus === "loading" &&
                          downloadingUuid?.uuid === invoice.uuid
                            ? "Downloading..."
                            : "Download"}
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={99}
                    className="whitespace-nowrap px-3 py-14 text-sm text-gray-500 text-center"
                  >
                    We did not find any invoices
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default BillingHistory;
