import { useAppSelector } from "../../store/hooks";
import { getCompanyName, getLogo } from "../../store/reducers/auth/authSlice";
import { PageTitle } from "./Fonts";

const Logo = () => {
  const logo = useAppSelector(getLogo);
  const name = useAppSelector(getCompanyName);

  if (!logo) {
    return <PageTitle className={"!mb-0"}>{name}</PageTitle>;
  } else {
    return (
      <div className={"flex items-center"}>
        <img
          src={logo}
          alt={name}
          className={"w-40 lg:w-48 object-contain h-10 lg:h-12"}
        />
      </div>
    );
  }
};

export default Logo;
