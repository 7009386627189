import React from "react";
import clsx from "clsx";

interface Props {
  children: React.ReactNode;
  size?: "normal" | "small";
}

const Container = ({ children, size = "normal" }: Props) => {
  return (
    <div
      className={clsx(
        "mx-auto max-w-6xl px-6 w-full",
        size === "small" && "sm:max-w-md"
      )}
    >
      {children}
    </div>
  );
};

export default Container;
