import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

export type Permission =
  | "subscription_create"
  | "subscription_update"
  | "subscription_cancel"
  | "subscription_display"
  | "recipient_create"
  | "recipient_update"
  | "recipient_remove"
  | "recipient_display"
  | "payment_card_create"
  | "payment_card_deactivate"
  | "payment_card_display"
  | "transactions_display"
  | "invoices_display"
  | "plans_display"
  | "account_create"
  | "account_display"
  | "account_update";

interface CompanyInfo {
  address: string;
  city: string;
  code: string;
  country: string;
  logo: string;
  name: string;
  website: string;
  zip_code: string;
  payment_gateways: PaymentGateways;
  self_service_permissions?: Permission[];
}

interface PaymentGateways {
  stripe_new?: string;
}

interface AuthType {
  token: string | null;
  info: CompanyInfo | null;
  infoChecked: boolean;
}

const initialState: AuthType = {
  token: localStorage.getItem("token") || null,
  info: null,
  infoChecked: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    clearToken(state) {
      state.token = null;
    },
    setCompanyInfo(state, action) {
      state.info = action.payload;
    },
    setInfoChecked(state, action) {
      state.infoChecked = action.payload;
    },
  },
});

export const signOut = () => {
  localStorage.removeItem("token");
  return authSlice.actions.clearToken();
};

export const { setToken, clearToken } = authSlice.actions as any;

export const hasToken = (state: RootState) =>
  state.auth.token !== null && state.auth.token !== "";

export const hasAccount = (state: RootState) => {
  const decoded = decodeToken(state);
  return decoded?.account_uuid !== null;
};

export const infoIsChecked = (state: RootState) => state.auth.infoChecked;

export const hasValidToken = (state: RootState) => {
  if (state.auth.token) {
    const decoded = decodeToken(state);
    return decoded?.exp > Date.now() / 1000;
  }
};

export const getToken = (state: RootState) => {
  // if token is sourounded with quotes, remove them
  if (state.auth.token?.startsWith('"')) {
    return state.auth.token.slice(1, -1);
  } else {
    return state.auth.token;
  }
};

export const decodeToken = (state: RootState) => {
  if (state.auth.token) {
    const token = state.auth.token.split(".")[1];
    return token ? JSON.parse(atob(token)) : null;
  }
};

export const getLogo = (state: RootState) => {
  return state.auth.info?.logo;
};

export const getCompanyName = (state: RootState) => {
  return state.auth.info?.name;
};

export const getEmail = (state: RootState) => {
  return decodeToken(state)?.email;
};

export const getAccountCode = (state: RootState) => {
  return decodeToken(state)?.account_code;
};

export const getStripeToken = (state: RootState) => {
  return state.auth.info?.payment_gateways?.stripe_new;
};

export const getPermissions = (state: RootState) => {
  return state.auth.info?.self_service_permissions;
};

export default authSlice.reducer;
