import { Permission } from "../store/reducers/auth/authSlice";

const usePermissions = (permissions: Permission[] | undefined) => {
  const userCan = (permission: Permission) => {
    return permissions?.includes(permission);
  };

  return {
    userCan,
  };
};

export default usePermissions;
