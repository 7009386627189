export const useMoneyFormat = () => {
  const formatMoney = (value: number, currency: string = "DKK") => {
    return new Intl.NumberFormat("da-DK", {
      style: "currency",
      currency,
    }).format(value);
  };

  return { formatMoney };
};
