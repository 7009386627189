import { PaymentCardData } from "../store/reducers/paymentMethods/paymentMethodsSlice";
import axios, { AxiosInstance } from "axios";
import toast from "react-hot-toast";

export interface CreateCardTokenData {
  exp_month: number;
  exp_year: number;
  number: number;
  cvc: string;
  currency?: string;
  name: string;
}

interface CreateCardTokenResponse {
  id: string;
  object: string;
  card: PaymentCardData;
}

interface CreatePaymentMethodResponse {
  id: string; // 'pm_x'
  object: string; // 'payment_method'
}

class StripeService {
  private api: AxiosInstance;
  constructor(apiKey: string) {
    console.log("apiKey", apiKey);
    this.api = axios.create({
      baseURL: "https://api.stripe.com/v1",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${apiKey}`,
      },
    });
  }
  createCardToken = async (card: CreateCardTokenData | null) => {
    if (!card) {
      throw new Error("Card data is not provided");
    }

    // make sure that data is in the x-www-form-urlencoded format
    // https://stackoverflow.com/questions/35325389/axios-post-request-to-send-form-data
    const formData = new URLSearchParams();
    formData.append("card[exp_month]", card.exp_month.toString());
    formData.append("card[exp_year]", card.exp_year.toString());
    formData.append("card[number]", card.number.toString());
    formData.append("card[cvc]", card.cvc.toString());
    formData.append("card[currency]", card.currency || "eur");
    formData.append("card[name]", card.name);

    return await this.api
      .post("/tokens", formData)
      .then((response) => {
        return response.data as CreateCardTokenResponse;
      })
      .catch((error) => {
        if (error.response.status === 402) {
          const message = error.response.data.error ? error.response.data.error.message: "Invalid card number"
          toast.error(message);
          throw new Error(message);
        }
        toast.error("Payment initialization failed.");
        throw new Error(error);
      });
  };

  createPaymentMethod = async (cardToken: string) => {
    if (!cardToken) {
      throw new Error("cardToken is not provided");
    }

    const formData = new URLSearchParams();
    formData.append("type", "card");
    formData.append("card[token]", cardToken.toString());

    return await this.api
      .post("/payment_methods", formData)
      .then((response) => {
        return response.data as CreatePaymentMethodResponse;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
}

export default StripeService;
