interface IconProps {
  className?: string;
}

const DownloadIcon = (props: IconProps) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_104_270)">
      <path
        d="M0.463867 9.75024V10.6788C0.463867 11.1714 0.65953 11.6437 1.00781 11.992C1.35609 12.3403 1.82847 12.536 2.32101 12.536H10.6782C11.1707 12.536 11.6431 12.3403 11.9914 11.992C12.3396 11.6437 12.5353 11.1714 12.5353 10.6788V9.75024"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.71387 5.57157L6.49958 8.82157L9.2853 5.57157"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 8.8216V0.464462"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_104_270">
        <rect
          width="13"
          height="13"
          fill="white"
          transform="translate(0 0.000244141)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DownloadIcon;
