import { useForm, useWatch } from "react-hook-form";
import { Form, useNavigate } from "react-router-dom";
import InputField from "../../ui/InputField";
import useValidationRegex from "../../../hooks/useValidationRegex";
import SelectField from "../../ui/SelectField";
import { HorizontalRule } from "../../ui/Fonts";
import PaymentCardForm from "./PaymentCardForm";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getEmail } from "../../../store/reducers/auth/authSlice";
import { useEffect } from "react";
import {
  setAccountData,
  setCheckoutStep,
  setIsSubmitting,
} from "../../../store/reducers/checkout/checkoutSlice";
import useCountryList from "../../../hooks/useCountryList";
const countryList = require("country-list");

const CreateAccountForm = () => {
  const email = useAppSelector(getEmail);
  const isSubmitting = useAppSelector((state) => state.checkout.isSubmitting);
  const dispatch = useAppDispatch();
  const accountData = useAppSelector((state) => state.checkout.accountData);
  const checkoutStep = useAppSelector((state) => state.checkout.checkoutStep);
  const planIsSelected = useAppSelector(
    (state) => state.checkout.planTerm !== null
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: email,
      firstName: accountData?.firstName,
      lastName: accountData?.lastName,
      companyName: accountData?.companyName,
      legalAddress: accountData?.legalAddress,
      legalCity: accountData?.legalCity,
      legalZipCode: accountData?.legalZipCode,
      country: accountData?.country,
      vatNumber: accountData?.vatNumber,
    },
  });

  const navigate = useNavigate();

  const { emailRegex, onlyNumbersRegex } = useValidationRegex();

  const onSubmit = async (data: any) => {
    dispatch(setAccountData(data));
  };

  useEffect(() => {
    console.log("Checkout step: ", checkoutStep);
    if (checkoutStep === "plans" && !planIsSelected) {
      navigate("/create-account");
    }
  }, [checkoutStep, planIsSelected]);

  useEffect(() => {
    if (isSubmitting) {
      console.log("Submitting account data...");
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, isSubmitting]);

  const countries = useCountryList();

  return (
    <>
      <Form method={"post"} onSubmit={() => handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <InputField
            name={"firstName"}
            register={register}
            required={true}
            label={"First name"}
            errors={errors}
          />
          <InputField
            name={"lastName"}
            required={true}
            register={register}
            label={"Last name"}
            errors={errors}
          />
        </div>
        <HorizontalRule />
        <InputField
          name={"companyName"}
          required={true}
          register={register}
          label={"Company name"}
          errors={errors}
        />
        <InputField
          name={"legalAddress"}
          required={true}
          register={register}
          label={"Legal address"}
          errors={errors}
        />
        <div className="grid grid-cols-2 gap-4">
          <InputField
            name={"legalCity"}
            required={true}
            register={register}
            label={"Legal city"}
            errors={errors}
          />
          <InputField
            name={"legalZipCode"}
            required={true}
            register={register}
            label={"Legal zip-code"}
            errors={errors}
          />
        </div>
        <SelectField
          name={"country"}
          required={true}
          register={register}
          label={"Country"}
          errors={errors}
          options={countries}
          placeholder={"Select country"}
        />
        <InputField
          name={"vatNumber"}
          required={true}
          register={register}
          label={"VAT"}
          errors={errors}
        />
      </Form>
      <HorizontalRule />
      <PaymentCardForm />
    </>
  );
};

export default CreateAccountForm;
