import InputField from "../../ui/InputField";
import Button from "../../ui/Button";
import { useForm } from "react-hook-form";
import { Form } from "react-router-dom";
import { useRef, useState } from "react";
import useSendAuthEmail from "../../../hooks/useSendAuthEmail";
import useValidationRegex from "../../../hooks/useValidationRegex";
import ErrorMessage from "../../ui/ErrorMessage";
import { isLoading } from "../../../store/reducers/plans/plansSlice";

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useRef<HTMLFormElement>(null);

  const sendAuthEmail = useSendAuthEmail();
  const { emailRegex } = useValidationRegex();

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    setSubmissionError(null);
    // todo handle logic here
    await sendAuthEmail(data.email)
      .then(() => {
        form.current?.submit();
      })
      .catch((error) => {
        setSubmissionError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Form
      ref={form}
      method={"get"}
      action={"/check-your-email"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={"space-y-4 text-left"}>
        <InputField
          placeholder={"Write your email here"}
          required={true}
          label={"Email"}
          rules={{
            pattern: emailRegex,
          }}
          register={register}
          name={"email"}
          errors={errors}
        />
        <Button disabled={isLoading} block={true}>
          {isLoading ? "Please wait..." : "Login"}
        </Button>
        {submissionError && <ErrorMessage message={submissionError} />}
      </div>
    </Form>
  );
};

export default LoginForm;
