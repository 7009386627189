import { IntervalType, PlanTerm } from "../utils/types";

const useFormats = () => {
  const planTypeName = (planType: IntervalType) => {
    switch (planType) {
      case "month":
        return "Monthly";
      case "year":
        return "Yearly";
      default:
        return "Daily";
    }
  };

  const planTermsPeriod = (terms: PlanTerm) => {
    switch (terms.interval_type) {
      case "month":
        return terms.interval_count === 1 ? "month" : `${terms.interval_count} months`;
      case "year":
        return terms.interval_count === 1 ? "year" : `${terms.interval_count} years`;
      default:
        return terms.interval_count === 1 ? "day" : `${terms.interval_count} days`;
    }
  }

  const planTermsBillingInterval = (terms: PlanTerm) => {
    if (terms.interval_count === 1) return planTypeName(terms.interval_type);
    return `every ${planTermsPeriod(terms)}`;
  }

  const sentenceCase = (str: string) => {
    if (str.length === 0) {
      return str;
    }
    return str[0].toUpperCase() + str.substr(1);
  };

  return {
    planTermsBillingInterval,
    planTermsPeriod,
    planTypeName,
    sentenceCase,
  };
};

export default useFormats;
