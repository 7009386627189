import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

const Popup = ({ children }: Props) => {
  return (
    <div
      className={
        "fixed inset-0 z-20 bg-black/20 py-20 overflow-hidden flex items-start justify-center"
      }
    >
      <div className={"max-w-xl w-full bg-white rounded-lg mx-4 p-6 md:p-8"}>
        {children}
      </div>
    </div>
  );
};

export default Popup;
