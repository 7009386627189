import { PaymentCardData } from "../store/reducers/paymentMethods/paymentMethodsSlice";
import PaymentCard from "../components/ui/icons/PaymentCard";

const useCardFormat = () => {
  const stripXCardNumber = (paymentMethod: PaymentCardData) =>
    paymentMethod.card_number.slice(-4);

  const cardExpiry = (paymentMethod: PaymentCardData) =>
    "Expiry " + paymentMethod.month + "/" + paymentMethod.year;

  return {
    lastDigitsCardNumber: stripXCardNumber,
    cardExpiry,
  };
};

export default useCardFormat;
