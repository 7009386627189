export const useAuth = () => {
  // get subdomain from url
  const domain = window.location.hostname.split(".");

  // if subdomain is not localhost, return subdomain
  const subdomain = domain[0] !== "localhost" ? domain[0] : process.env.REACT_APP_ORGANIZATION_SLUG;

  const token = localStorage.getItem("token");

  // remove first character and last two characters from token
  // if token has quotes around it
  const tokenWithoutQuotes = token?.slice(1, -1);

  // decode token
  const decodedToken = (tokenWithoutQuotes: string) => {
    const token = tokenWithoutQuotes.split(".")[1];
    return token ? JSON.parse(atob(token)) : null;
  };

  return {
    token: tokenWithoutQuotes,
    decodedToken: decodedToken(tokenWithoutQuotes ?? ""),
    organizationSlug: subdomain ?? "some",
  };
};
