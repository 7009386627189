import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import loggerMiddleware from "./middleware/logger";
import monitorReducersEnhancer from "./enhancers/monitorReducer";
import rootReducer from "./reducers";

export const store = configureStore({
  reducer: rootReducer,
  middleware: [loggerMiddleware, ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActionPaths: ['payload.payment_gateway.stripe.stripe'],
      ignoredPaths: ['singlePayment.paymentData.payment_gateway.stripe.stripe'],
    },
  })],
  enhancers: [monitorReducersEnhancer],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
