interface Props {
  value?: number;
  setValue?: (value: number) => void;
}

const QuantitySelector = ({ value = 1, setValue = () => {} }: Props) => {
  return (
    <div className="">
      <select
        className={
          "rounded-md h-10 border-gray-300 focus:ring-0 focus:border-black px-4 py-2 pr-8 placeholder-gray-500"
        }
        value={value}
        onChange={(e) => setValue(parseInt(e.target.value))}
      >
        <option value="1">Qty 1</option>
        {[...Array(99)].map((_, i) => (
          <option key={"option-" + i} value={i + 2}>
            Qty {i + 2}
          </option>
        ))}
      </select>
    </div>
  );
};

export default QuantitySelector;
