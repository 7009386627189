import FenerumAPIService from "./FenerumAPIService";
import toast from "react-hot-toast";

class PaymentMethodService extends FenerumAPIService {
  getPaymentCards = async () => {
    return await this.get("v1/paymentcards")
      .then((response) => {
        return response?.results;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  createPaymentCard = async (account_id: string | undefined, token: string) => {
    if (!account_id) {
      throw new Error("Account ID is not provided");
    }

    return await this.post("v1/paymentcards", {
      account: account_id,
      gateway: "stripe_new",
      token: token,
    }) // TODO: Currency?
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.message);
        throw new Error(error);
      });
  };

  getPaymentCard = async (uuid: string) => {
    return await this.get(`v1/paymentcards/${uuid}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  disablePaymentCard = async (uuid: string) => {
    return await this.post(`v1/paymentcards/${uuid}/disable`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
}

export default PaymentMethodService;
