import Popup from "../../layout/Popup";
import { HorizontalRule, PageTitle } from "../../ui/Fonts";

const EditPaymentMethod = () => {
  return (
    <Popup>
      <PageTitle>Edit payment method</PageTitle>
      <HorizontalRule />
    </Popup>
  );
};

export default EditPaymentMethod;
