interface Props {
  message: string;
}

const Toast = ({ message }: Props) => {
  return (
    <div
      className={
        "bg-white rounded-md shadow-sm border border-gray-300 px-4 py-2 "
      }
    >
      <p className={"text-gray-900"}>{message}</p>
    </div>
  );
};

export default Toast;
