import Popup from "../../layout/Popup";
import { HorizontalRule, PageTitle, Paragraph } from "../../ui/Fonts";
import EditAccountForm from "../../layout/forms/EditAccountForm";

const EditAccount = () => {
  return (
    <Popup>
      <PageTitle>Edit account</PageTitle>
      <Paragraph>Edit your account details</Paragraph>
      <HorizontalRule />
      <EditAccountForm />
    </Popup>
  );
};

export default EditAccount;
