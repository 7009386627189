import PlansReducer from "./plans/plansSlice";
import AuthReducer from "./auth/authSlice";
import CheckoutReducer from "./checkout/checkoutSlice";
import AccountReducer from "./account/accountSlice";
import { combineReducers } from "@reduxjs/toolkit";
import PaymentMethodsReducer from "./paymentMethods/paymentMethodsSlice";
import SubscriptionReducer from "./subscriptions/subscriptionSlice";
import BillingHistoryReducer from "./billingHistory/billingHistorySlice";
import SinglePaymentReducer from "./payment/singlePaymentSlice";

const rootReducer = combineReducers({
  plans: PlansReducer,
  auth: AuthReducer,
  account: AccountReducer,
  checkout: CheckoutReducer,
  paymentMethods: PaymentMethodsReducer,
  subscriptions: SubscriptionReducer,
  billingHistory: BillingHistoryReducer,
  singlePayment: SinglePaymentReducer,
});

export default rootReducer;
