import { ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { HorizontalRule, PageTitle, Paragraph } from "../../ui/Fonts";
import PublicLayout from "../../layout/PublicLayout";
import Button from "../../ui/Button";
import PoweredBy from "../../ui/PoweredBy";
import { useMoneyFormat } from "../../../hooks/useMoneyFormat";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getCompanyName } from "../../../store/reducers/auth/authSlice";
import { useForm } from "react-hook-form";
import Label from "../../ui/Label";
import CreditCards from "../../ui/icons/CreditCards";
import clsx from "clsx";
import InputField from "../../ui/InputField";
import useCreditCardMask from "../../../hooks/useCreditCardMask";
import {
  getPaymentProcessingError,
  getSinglePaymentData,
  isPaymentFormSubmitting,
  isPaymentProcessing,
  paymentDataInitiationError,
  paymentDataIsLoading,
  setPaymentFormIsSubmitting,
  setPaymentProcessingError,
  setPaymentProcessingStep,
  submitPaymentForm,
} from "../../../store/reducers/payment/singlePaymentSlice";
import StripeService from "../../../services/StripeService";
import * as paymentIntents from "@stripe/stripe-js/types/stripe-js/payment-intents";
import GuestLayout from "../../layout/GuestLayout";

const PaymentFormComponent = () => {
  const dispatch = useAppDispatch();
  const paymentData = useAppSelector(getSinglePaymentData);
  const isSubmitting = useAppSelector(isPaymentFormSubmitting);
  const name = useAppSelector(getCompanyName);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const form = useRef<HTMLFormElement>(null);
  const { cardNumberMask, expirationDateMask, cvcMask } = useCreditCardMask();

  const handleCardNumberChange = (event: any) => {
    event.target.value = cardNumberMask(event.target.value);
  };

  const handleExpirationDateChange = (event: any) => {
    event.target.value = expirationDateMask(event.target.value);
  };

  const handleCvcChange = (event: any) => {
    event.target.value = cvcMask(event.target.value);
  };

  const onSubmit = async (data: any) => {
    if (!paymentData || !paymentData.payment_gateway.stripe) return;
    const clientSecret = paymentData.payment_gateway.stripe.client_secret;
    const stripe = paymentData.payment_gateway.stripe.stripe;
    const stripeService = new StripeService(
      paymentData.payment_gateway.stripe.publishable_key
    );
    stripeService
      .createCardToken({
        name: data.cardName,
        // number remove spaces
        number: parseInt(data.cardNumber.replace(/\s/g, "")),
        exp_month: parseInt(data.cardExpiry.split("/")[0]),
        exp_year: parseInt(data.cardExpiry.split("/")[1]),
        cvc: data.cardCvc,
      })
      .then((cardToken) => {
        const confirmPaymentData: paymentIntents.ConfirmCardPaymentData = {
          payment_method: {
            card: {
              token: cardToken.id,
            },
            billing_details: {
              name: data.cardName,
              email: data.email,
            },
          },
        };

        if (data.useInFuture)
          confirmPaymentData.setup_future_usage = "off_session";
        stripe
          ?.confirmCardPayment(clientSecret, confirmPaymentData)
          .then((result) => {
            if (result.error) {
              dispatch(
                setPaymentProcessingError(
                  `${result.error.message} (code=${result.error.code})`
                )
              );
            }
            dispatch(setPaymentFormIsSubmitting(false));
            dispatch(setPaymentProcessingStep("processing"));
          });
      })
      .catch((error) => {
        dispatch(setPaymentFormIsSubmitting(false));
      });
  };

  useEffect(() => {
    if (isSubmitting) {
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, isSubmitting]);

  useEffect(() => {
    if (isSubmitting && !isValid) {
      dispatch(setPaymentFormIsSubmitting(false));
    }
  }, [isSubmitting, isValid, dispatch]);

  return (
    <form
      ref={form}
      method={"post"}
      action={"/"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={"mb-4"}>
        <Label labelFor={"email"}>E-mail</Label>
        <InputField
          required={true}
          name={"email"}
          placeholder={"Enter your e-mail"}
          register={register}
          errors={errors}
          type={"email"}
        />
        <HorizontalRule />
        <Label>Card information</Label>
        <div className={"relative"}>
          <CreditCards
            className={"absolute top-1/2 right-3 -translate-y-1/2"}
          />
          <input
            required={true}
            {...register("cardNumber", { required: true })}
            className={clsx(
              "border pl-4 pr-20 border-gray-300 rounded-t-md p-2 w-full shadow-none focus:outline-black focus:outline-0 focus:ring-black",
              errors &&
                errors.cardNumber &&
                "border-red-500 focus:border-red-500"
            )}
            onChange={handleCardNumberChange}
            placeholder={"XXXX XXXX XXXX XXXX"}
          />
        </div>
        <div className={"flex"}>
          <div className="w-1/2">
            <input
              required={true}
              {...register("cardExpiry", { required: true })}
              className={clsx(
                "border pl-4 pr-20 border-gray-300 rounded-bl-md border-t-0 p-2 w-full focus:outline-0 focus:ring-black",
                errors &&
                  errors.cardExpiry &&
                  "border-red-500 focus:border-red-500"
              )}
              onChange={handleExpirationDateChange}
              placeholder={"MM / YY"}
            />
          </div>
          <div className="w-1/2">
            <input
              required={true}
              {...register("cardCvc", { required: true })}
              className={clsx(
                "border pl-4 pr-20 border-gray-300 rounded-br-md border-l-0 border-t-0 p-2 w-full focus:outline-0 focus:ring-black",
                errors &&
                  errors.cardCvc &&
                  "border-red-500 focus:border-red-500"
              )}
              onChange={handleCvcChange}
              placeholder={"CVC"}
            />
          </div>
        </div>
      </div>
      <Label labelFor={"cardName"}>Name on card</Label>
      <InputField
        required={true}
        name={"cardName"}
        placeholder={"Enter your full name on card"}
        register={register}
        errors={errors}
      />
      <label htmlFor={"useInFuture"} className={"flex"}>
        <input
          {...register("useInFuture", { required: false })}
          className={
            "h-4 w-4 rounded border-gray-300 text-gray-900 focus:ring-gray-900"
          }
          type={"checkbox"}
          id={"useInFuture"}
        />
        <span className={"text-gray-500 text-sm mb-1 ml-2"}>
          Save card and authorize {name} to use this card to charge all
          subscriptions moving forward.
        </span>
      </label>
    </form>
  );
};

const PaymentDetailComponent = () => {
  const dispatch = useAppDispatch();
  const paymentData = useAppSelector(getSinglePaymentData);
  const isSubmitting = useAppSelector(isPaymentFormSubmitting);
  const name = useAppSelector(getCompanyName);
  const { formatMoney } = useMoneyFormat();

  const submitForm = () => {
    dispatch(submitPaymentForm());
  };

  if (!paymentData)
    return (
      <div className={"text-center"}>
        <Paragraph className={"max-w-xs mx-auto"}>
          Critical error: there is no payment data
        </Paragraph>
      </div>
    );

  return (
    <>
      <div className={"flex flex-wrap justify-between -mx-4"}>
        <div className={"w-full md:w-1/2 px-4 mb-4"}>
          <PageTitle className={"mb-4"}>Pay with payment card</PageTitle>
          <PaymentFormComponent />
        </div>
        <div className={"w-full md:w-1/2 px-4"}>
          <div className={"bg-gray-100 p-4 lg:p-8 rounded-lg"}>
            <Paragraph className={"mb-2"}>You have to pay</Paragraph>
            <h2
              className={
                "text-3xl sm:text-4xl md:text-4xl font-medium text-gray-900"
              }
            >
              {formatMoney(paymentData.amount, paymentData.currency)}
            </h2>
            <HorizontalRule />
            <div className={"flex justify-between"}>
              <div className={"mb-2 flex items-center"}>
                <Paragraph className={"!mb-0 !text-base"}>
                  Invoice nr.
                </Paragraph>
              </div>
              <div className={"text-right"}>
                <Paragraph className={"!mb-0 !text-base"}>
                  #{paymentData.invoice_number}
                </Paragraph>
              </div>
            </div>
            <div className={"flex justify-between"}>
              <div className={"mb-2 flex items-center"}>
                <Paragraph className={"!mb-0 !text-base"}>Date</Paragraph>
              </div>
              <div className={"text-right"}>
                <Paragraph className={"!mb-0 !text-base"}>
                  {paymentData.date}
                </Paragraph>
              </div>
            </div>
            <div className={"flex justify-between"}>
              <div className={"mb-6 flex items-center"}>
                <Paragraph className={"!mb-0 !text-base"}>Due date</Paragraph>
              </div>
              <div className={"text-right"}>
                <Paragraph className={"!mb-0 !text-base"}>
                  {paymentData.due_date}
                </Paragraph>
              </div>
            </div>
            <div>
              <Button block={true} onClick={submitForm} disabled={isSubmitting}>
                {isSubmitting ? "Please wait..." : "Pay now"}
              </Button>
              <Paragraph className={"mt-4 text-center"}>
                By confirming, you allow {name} to charge your card for this
                payment in accordance with their terms.
              </Paragraph>
            </div>
          </div>
          <div className={"flex justify-end"}>
            <PoweredBy />
          </div>
        </div>
      </div>
    </>
  );
};

const PaymentSummary = () => {
  const paymentData = useAppSelector(getSinglePaymentData);
  const { formatMoney } = useMoneyFormat();

  if (!paymentData)
    return (
      <div className={"text-center"}>
        <Paragraph className={"max-w-xs mx-auto"}>
          Critical error: there is no payment data
        </Paragraph>
      </div>
    );

  return (
    <>
      <HorizontalRule />
      <div className={"flex justify-between"}>
        <div className={"mb-2 flex items-center"}>
          <Paragraph className={"!mb-0 !text-base"}>Amount</Paragraph>
        </div>
        <div className={"text-right"}>
          <Paragraph className={"!mb-0 !text-base"}>
            {formatMoney(paymentData.amount, paymentData.currency)}
          </Paragraph>
        </div>
      </div>
      <div className={"flex justify-between"}>
        <div className={"mb-2 flex items-center"}>
          <Paragraph className={"!mb-0 !text-base"}>Invoice nr.</Paragraph>
        </div>
        <div className={"text-right"}>
          <Paragraph className={"!mb-0 !text-base"}>
            #{paymentData.invoice_number}
          </Paragraph>
        </div>
      </div>
      <div className={"flex justify-between"}>
        <div className={"mb-2 flex items-center"}>
          <Paragraph className={"!mb-0 !text-base"}>Date</Paragraph>
        </div>
        <div className={"text-right"}>
          <Paragraph className={"!mb-0 !text-base"}>
            {paymentData.date}
          </Paragraph>
        </div>
      </div>
      <div className={"flex justify-between"}>
        <div className={"mb-6 flex items-center"}>
          <Paragraph className={"!mb-0 !text-base"}>Due date</Paragraph>
        </div>
        <div className={"text-right"}>
          <Paragraph className={"!mb-0 !text-base"}>
            {paymentData.due_date}
          </Paragraph>
        </div>
      </div>
      <HorizontalRule />
    </>
  );
};

const PaymentStatusComponent = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const paymentProcessingError = useAppSelector(getPaymentProcessingError);
  const paymentData = useAppSelector(getSinglePaymentData);
  const name = useAppSelector(getCompanyName);

  const paymentSucceeded = useMemo(() => {
    return !isLoading && !paymentProcessingError;
  }, [isLoading, paymentProcessingError]);
  const message = useMemo(() => {
    if (isLoading) return null;
    if (paymentSucceeded) return `Your payment has been confirmed by ${name}`;
    return paymentProcessingError;
  }, [isLoading, paymentSucceeded, paymentProcessingError, name]);
  const title = useMemo(() => {
    if (isLoading) return "Checking Payment Status";
    if (paymentSucceeded) return "Payment Confirmed";
    if (paymentProcessingError) return "Payment Failed";
    return "Unknown Payment Status";
  }, [isLoading, paymentSucceeded, paymentProcessingError]);

  function checkStatus() {
    if (paymentData?.payment_gateway.stripe) {
      const stripe = paymentData.payment_gateway.stripe.stripe;
      stripe
        ?.retrievePaymentIntent(
          paymentData?.payment_gateway.stripe.client_secret
        )
        .then((result) => {
          if (result.paymentIntent?.status === "processing") {
            setTimeout(checkStatus, 1000);
            return;
          } else if (result.paymentIntent?.status === "succeeded") {
            dispatch(setPaymentProcessingError(null));
          } else {
            console.log(result);
            if (result.paymentIntent?.last_payment_error) {
              dispatch(
                setPaymentProcessingError(
                  `${result.paymentIntent.last_payment_error.message} (code=${result.paymentIntent.last_payment_error.code})`
                )
              );
            } else if (result.error) {
              dispatch(
                setPaymentProcessingError(
                  `${result.error.message} (code=${result.error.code})`
                )
              );
            } else {
              dispatch(setPaymentProcessingError("Unknown error"));
            }
          }
          setIsLoading(false);
        });
    }
  }

  useEffect(() => {
    if (!paymentProcessingError) {
      setTimeout(checkStatus, 1000);
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <InfoLoaderComponent
      isLoading={isLoading}
      message={paymentProcessingError || message}
      title={title}
    >
      {paymentSucceeded && <PaymentSummary />}
    </InfoLoaderComponent>
  );
};

const PaymentInitializationComponent = () => {
  const isLoading = useAppSelector(paymentDataIsLoading);
  const initializationError = useAppSelector(paymentDataInitiationError);
  const message = useMemo(() => {
    if (isLoading) return "Loading data about payment...";
    return initializationError;
  }, [isLoading, initializationError]);

  return (
    <InfoLoaderComponent
      isLoading={isLoading}
      message={message}
      title={
        initializationError
          ? "Could not initialize new payment"
          : "Payment initialization"
      }
    />
  );
};

interface InfoLoaderComponentProps {
  children?: ReactNode;
  message?: string | null;
  title: string;
  isLoading: boolean;
}

const InfoLoaderComponent = ({
  children,
  title,
  message,
  isLoading = false,
}: InfoLoaderComponentProps) => {
  return (
    <GuestLayout disableMiddleware={true}>
      <div className={"text-center"}>
        <PageTitle>{title}</PageTitle>
        {message && (
          <Paragraph className={"max-w-xs mx-auto"}>{message}</Paragraph>
        )}
        {children}
        {isLoading && (
          <div className={"flex justify-center"}>
            <div className={"w-1/2"}>
              <div className={"flex justify-center mt-12"}>
                <div className={"w-1/2 text-center"}>
                  <div
                    className={
                      "animate-spin inline-block rounded-full h-16 w-16 border-b-2 border-gray-900"
                    }
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </GuestLayout>
  );
};

const PaymentProcessingComponent = () => {
  const processingStep = useAppSelector(isPaymentProcessing);
  const paymentProcessingError = useAppSelector(getPaymentProcessingError);

  return (
    <>
      {processingStep || paymentProcessingError ? (
        <PaymentStatusComponent />
      ) : (
        <PublicLayout hide={["poweredBy"]}>
          <PaymentDetailComponent />
        </PublicLayout>
      )}
    </>
  );
};

const PayView = () => {
  const isLoading = useAppSelector(paymentDataIsLoading);
  const initializationError = useAppSelector(paymentDataInitiationError);

  return (
    <>
      {isLoading || initializationError ? (
        <PaymentInitializationComponent />
      ) : (
        <PaymentProcessingComponent />
      )}
    </>
  );
};

export default PayView;
