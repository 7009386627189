import Container from "./Container";
import React from "react";
import Logo from "../ui/Logo";
import PoweredBy from "../ui/PoweredBy";
import ApplicationSkeleton from "./ApplicationSkeleton";


type Hide = "poweredBy" | "logo";

interface Props {
  children: React.ReactNode;
  hide?: Hide[];
  containerSize?: "normal" | "small";
}

const PublicLayout = ({
  children,
  hide = [],
  containerSize = "normal",
}: Props) => {
  const hideLogo = hide.length > 0 ? hide.includes("logo") : false;
  const hidePoweredBy = hide.length > 0 ? hide.includes("poweredBy") : false;

  return (
    <ApplicationSkeleton>
      <div className={"flex items-center justify-center"}>
        <Container size={containerSize}>
          {!hideLogo && (
            <div className={"mb-6 flex justify-right"}>
              <Logo />
            </div>
          )}
          {children}
          {!hidePoweredBy && (
            <div className={"mt-6"}>
              <PoweredBy />
            </div>
          )}
        </Container>
      </div>
    </ApplicationSkeleton>
  );
};

export default PublicLayout;
