import FenerumAPIService from "./FenerumAPIService";
import toast from "react-hot-toast";

interface InvoiceResponse {
  pdf_base64: string;
}

class BillingHistoryService extends FenerumAPIService {
  getInvoices() {
    return this.get("v1/invoices")
      .then((response) => {
        console.log(response);
        return response.results;
      })
      .catch((error) => {
        throw error;
      });
  }

  downloadInvoice(invoiceId: string) {
    return this.get(`v1/invoices/${invoiceId}`)
      .then((response: InvoiceResponse) => {
        return response?.pdf_base64; // todo handle download response to return string base64
      })
      .catch((error) => {
        toast.error(error.message);
        throw error;
      });
  }
}

export default BillingHistoryService;
