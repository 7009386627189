const FenerumLogo = () => {
  return (
    <svg
      width="77"
      height="17"
      viewBox="0 0 77 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.58545 0.795441H6.48092C6.48028 2.29226 5.88538 3.72758 4.82698 4.78599C3.76857 5.8444 2.33324 6.43929 0.836426 6.43993L0.836426 9.54446C3.15681 9.54446 5.38216 8.62269 7.02292 6.98193C8.66368 5.34117 9.58545 3.11583 9.58545 0.795441Z"
        fill="currentColor"
      />
      <path
        d="M3.37549 16.2046H6.4776C6.4776 14.7079 7.07216 13.2725 8.13048 12.2142C9.1888 11.1558 10.6242 10.5613 12.1209 10.5613V7.45674C10.9723 7.4569 9.83492 7.68329 8.77379 8.123C7.71267 8.5627 6.74854 9.20711 5.93645 10.0194C5.12437 10.8317 4.48023 11.796 4.04082 12.8573C3.60141 13.9185 3.37533 15.0559 3.37549 16.2046Z"
        fill="currentColor"
      />
      <path
        d="M21.7983 10.028C21.7983 7.65725 23.5694 5.74231 26.0961 5.74231C28.5708 5.74231 30.256 7.57263 30.256 10.248V10.7231H24.0905C24.2283 11.7555 25.0709 12.6199 26.4793 12.6199C27.1853 12.6199 28.1646 12.3153 28.6977 11.8075L29.6648 13.1954C28.8404 13.9413 27.5335 14.3305 26.2436 14.3305C23.706 14.3354 21.7983 12.6538 21.7983 10.028ZM26.0961 7.45294C24.7385 7.45294 24.1533 8.45273 24.0506 9.26634H28.1416C28.0896 8.48658 27.5395 7.45294 26.0961 7.45294Z"
        fill="currentColor"
      />
      <path
        d="M36.7694 14.1275V9.1818C36.7694 8.04661 36.1649 7.65734 35.2389 7.65734C34.8703 7.6608 34.5076 7.74999 34.1794 7.91785C33.8513 8.08571 33.5667 8.32763 33.3481 8.62448V14.1299H31.166V5.9467H33.3481V7.01298C33.7131 6.60645 34.1609 6.28273 34.6613 6.06358C35.1617 5.84443 35.7033 5.73491 36.2496 5.74239C38.0714 5.74239 38.9491 6.7591 38.9491 8.35126V14.1275H36.7694Z"
        fill="currentColor"
      />
      <path
        d="M39.8618 10.028C39.8618 7.65725 41.6329 5.74231 44.1596 5.74231C46.6354 5.74231 48.3195 7.57263 48.3195 10.248V10.7231H42.1539C42.2906 11.7555 43.1332 12.6199 44.5428 12.6199C45.2476 12.6199 46.2268 12.3153 46.76 11.8075L47.7271 13.1954C46.8966 13.9413 45.5861 14.3354 44.2974 14.3354C41.7707 14.3354 39.8618 12.6538 39.8618 10.028ZM44.1596 7.45294C42.8019 7.45294 42.2168 8.45273 42.1141 9.26634H46.2051C46.1531 8.48658 45.603 7.45294 44.1596 7.45294Z"
        fill="currentColor"
      />
      <path
        d="M49.23 14.1274V5.94662H51.4133V7.06367C51.9972 6.33831 53.0284 5.74231 54.0597 5.74231V7.84343C53.8604 7.80599 53.6579 7.78897 53.4552 7.79265C52.7298 7.79265 51.7699 8.19885 51.4097 8.72353V14.1274H49.23Z"
        fill="currentColor"
      />
      <path
        d="M60.5732 14.1274V13.0938C60.2007 13.4931 59.7486 13.8099 59.2462 14.0237C58.7437 14.2376 58.202 14.3437 57.656 14.3354C55.8342 14.3354 54.9746 13.3537 54.9746 11.7603V5.95747H57.1579V10.9141C57.1579 12.0493 57.7624 12.4216 58.6872 12.4216C59.0519 12.4166 59.4108 12.3303 59.7378 12.169C60.0649 12.0078 60.3519 11.7757 60.578 11.4895V5.94659H62.7613V14.1274H60.5732Z"
        fill="currentColor"
      />
      <path
        d="M73.9634 14.1202L76.1637 14.1274V8.21578C76.1637 6.53899 75.2522 5.74231 73.7229 5.74231C72.4511 5.74231 71.3509 6.48822 70.8698 7.23292C70.5603 6.30204 69.7818 5.74231 68.5656 5.74231C67.2938 5.74231 66.1937 6.52207 65.8503 7.01289V5.94662H63.667V14.1274H65.8503V8.62198C66.1767 8.165 66.8175 7.65484 67.5694 7.65484C68.4628 7.65484 68.8074 8.19644 68.8074 8.95806V14.125H71.0076V8.60505C71.3159 8.165 71.9518 7.65725 72.7255 7.65725C73.6201 7.65725 73.9634 8.19885 73.9634 8.96048V14.1202Z"
        fill="currentColor"
      />
      <path
        d="M18.827 6.31909C18.8246 6.10312 18.8686 5.88915 18.956 5.69164C19.0434 5.49413 19.1721 5.31769 19.3336 5.17423C19.5421 4.98732 19.8054 4.87282 20.0843 4.84782C20.3605 4.83188 20.6348 4.90245 20.8689 5.04971L21.8494 3.64252C19.3541 1.80857 16.7259 3.57603 16.6353 5.82947V14.1215H18.8258V9.26646H20.8665V7.45306H18.8258C18.8258 7.07588 18.8262 6.69789 18.827 6.31909Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FenerumLogo;
