interface Props {
  className?: string;
}

const PlusIcon = (props: Props) => (
  <svg
    width="13"
    height="13"
    className={props.className}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 11.5757V1.36185"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.39258 6.50021H11.6065"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlusIcon;
