interface Props {
  currency: string;
}

const FlagByCurrency = ({ currency }: Props) => {
  const flag = currency.toLowerCase();
  return (
    <img
      src={`/images/flags/${flag}.svg`}
      alt={`${currency} flag`}
      className={"w-4 h-4"}
    />
  );
};

export default FlagByCurrency;
