import { useForm } from "react-hook-form";
import InputField from "../../ui/InputField";
import SelectField from "../../ui/SelectField";
import ButtonGroup from "../ButtonGroup";
import Button from "../../ui/Button";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Form, useNavigate } from "react-router-dom";
import useCountryList from "../../../hooks/useCountryList";
import { useEffect } from "react";
import { updateAccount } from "../../../store/reducers/account/accountSlice";

const EditAccountForm = () => {
  const account = useAppSelector((state) => state.account.account);
  const countries = useCountryList();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const status = useAppSelector((state) => state.account.status);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: async () => {
      return {
        company_name: account?.company_name,
        legal_address: account?.legal_address,
        legal_city: account?.legal_city,
        legal_zipcode: account?.legal_zipcode,
        legal_country: account?.legal_country,
        legal_vat_number: account?.legal_vat_number,
      };
    },
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  useEffect(() => {
    if (account) {
      // set default values
      reset({
        company_name: account?.company_name,
        legal_address: account?.legal_address,
        legal_city: account?.legal_city,
        legal_zipcode: account?.legal_zipcode,
        legal_country: account?.legal_country,
        legal_vat_number: account?.legal_vat_number,
      });
    }
  }, [account]);

  const onSubmit = async (data: any) => {
    console.log("edit account", data);
    dispatch<any>(updateAccount({ code: account?.code, data: data })).then(
      () => {
        navigate("/");
      }
    );
  };

  return (
    <Form method={"get"} onSubmit={() => handleSubmit(onSubmit)}>
      <InputField
        name={"company_name"}
        register={register}
        required={true}
        label={"Company name"}
        errors={errors}
      />
      <InputField
        name={"legal_address"}
        required={true}
        register={register}
        label={"Legal address"}
        errors={errors}
      />
      <div className="grid grid-cols-2 gap-4">
        <InputField
          name={"legal_city"}
          required={true}
          register={register}
          label={"Legal city"}
          errors={errors}
        />
        <InputField
          name={"legal_zipcode"}
          required={true}
          register={register}
          label={"Legal zip-code"}
          errors={errors}
        />
      </div>
      <SelectField
        name={"legal_country"}
        required={true}
        register={register}
        label={"Country"}
        errors={errors}
        options={countries}
        placeholder={"Select country"}
      />
      <InputField
        name={"legal_vat_number"}
        required={true}
        register={register}
        label={"VAT"}
        errors={errors}
      />
      <ButtonGroup className={"justify-end"}>
        <Button variant={"secondary"} href={"/"}>
          Cancel
        </Button>
        <Button
          disabled={status !== "succeeded"}
          type={"submit"}
          onClick={() => handleSubmit(onSubmit)()}
        >
          Save changes
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default EditAccountForm;
