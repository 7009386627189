import FenerumLogo from "./FenerumLogo";

const PoweredBy = () => {
  return (
    <div className="flex text-sm mt-4 items-center justify-center text-gray-400">
      <span className={"mr-1"}>Powered by</span>
      <a href="https://www.fenerum.com" target={"_blank"}>
        <FenerumLogo />
      </a>
    </div>
  );
};

export default PoweredBy;
