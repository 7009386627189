import SetPlansForm from "../../layout/forms/SetPlansForm";
import CreateAccountForm from "../../layout/forms/CreateAccountForm";
import CheckoutBox from "../../ui/CheckoutBox";
import { AccountStep } from "../../../store/reducers/checkout/checkoutSlice";
import GoBackLink from "../../ui/GoBackLink";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useEffect } from "react";
import { planTermsHasMultipleCurrencies } from "../../../store/reducers/plans/plansSlice";

interface Props {
  step?: AccountStep;
}

const ManagePlansView = ({ step = "create-plan" }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={"mb-4"}>
        <GoBackLink onClick={() => navigate(-1)} />
      </div>
      <div
        className={
          "flex flex-wrap justify-between space-y-4 md:space-y-0 md:-mx-5"
        }
      >
        <div className={"w-full md:w-1/2 md:px-5"}>
          <SetPlansForm step={step} />
        </div>
        <div className={"w-full md:w-1/2 md:px-5"}>
          <CheckoutBox step={step} />
        </div>
      </div>
    </>
  );
};

export default ManagePlansView;
