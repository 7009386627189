import { useAppDispatch } from "../store/hooks";
import { setToken } from "../store/reducers/auth/authSlice";
import SelfServiceAuth from "./SelfServiceAuth";

export const validateTokenAndSetInfo = async (
  token: string,
  dispatch: ReturnType<typeof useAppDispatch>,
  setValue: (value: string | null) => void
) => {
  try {
    const selfServiceAuth = new SelfServiceAuth();
    const response = await selfServiceAuth.validate(token);

    if (response) {
      const companyInfo = await selfServiceAuth.info(response);
      dispatch({
        type: "auth/setCompanyInfo",
        payload: companyInfo,
      });
      setValue(response);
      dispatch(setToken(response));
    }
  } catch (error) {
    throw error;
  }
};
