interface IconProps {
  className?: string;
}

const ActionDotsIcon = (props: IconProps) => {
  return (
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 18.5C22 18.1022 22.158 17.7206 22.4393 17.4393C22.7206 17.158 23.1022 17 23.5 17C23.8978 17 24.2794 17.158 24.5607 17.4393C24.842 17.7206 25 18.1022 25 18.5C25 18.8978 24.842 19.2794 24.5607 19.5607C24.2794 19.842 23.8978 20 23.5 20C23.1022 20 22.7206 19.842 22.4393 19.5607C22.158 19.2794 22 18.8978 22 18.5ZM16.5 18.5C16.5 18.1022 16.658 17.7206 16.9393 17.4393C17.2206 17.158 17.6022 17 18 17C18.3978 17 18.7794 17.158 19.0607 17.4393C19.342 17.7206 19.5 18.1022 19.5 18.5C19.5 18.8978 19.342 19.2794 19.0607 19.5607C18.7794 19.842 18.3978 20 18 20C17.6022 20 17.2206 19.842 16.9393 19.5607C16.658 19.2794 16.5 18.8978 16.5 18.5ZM11 18.5C11 18.1022 11.158 17.7206 11.4393 17.4393C11.7206 17.158 12.1022 17 12.5 17C12.8978 17 13.2794 17.158 13.5607 17.4393C13.842 17.7206 14 18.1022 14 18.5C14 18.8978 13.842 19.2794 13.5607 19.5607C13.2794 19.842 12.8978 20 12.5 20C12.1022 20 11.7206 19.842 11.4393 19.5607C11.158 19.2794 11 18.8978 11 18.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ActionDotsIcon;
