import VisaCard from "./VisaCard";
import MasterCard from "./MasterCard";
import DefaultCard from "./DefaultCard";
import AmexCard from "./AmexCard";

interface Props {
  name:
    | "visa"
    | "mastercard"
    | "amex"
    | "discover"
    | "diners"
    | "jcb"
    | "unionpay"
    | any;
}

const icons = {
  visa: VisaCard,
  mastercard: MasterCard,
  amex: AmexCard,
  default: DefaultCard,
} as any;

const PaymentCard = ({ name }: Props) => {
  let IconComponent = icons[name] || icons.default;

  return <IconComponent />;
};

export default PaymentCard;
