import { ReactNode } from "react";
import { TagColor } from "../../utils/types";
import clsx from "clsx";

export interface TagProps {
  children?: ReactNode;
  text?: string;
  color?: TagColor;
}

const Tag = ({ children, color = "green", text }: TagProps) => {
  return (
    <div
      className={clsx(
        "px-3 py-1 rounded-full text-xs inline-flex items-center",
        color === "green" && "bg-green-100",
        color === "red" && "bg-red-100",
        color === "yellow" && "bg-yellow-100",
        color === "blue" && "bg-blue-200"
      )}
    >
      <span
        className={clsx(
          "w-2 h-2 inline-block rounded-full mr-1.5",
          color === "green" && "bg-green-500",
          color === "red" && "bg-red-500",
          color === "yellow" && "bg-yellow-500",
          color === "blue" && "bg-blue-500"
        )}
      ></span>
      {children && <span>{children}</span>}
      {text && <span>{text}</span>}
    </div>
  );
};

export default Tag;
