const countryList = require("country-list");

const useCountryList = () => {
  const countries = countryList.getCodes().map((code: string) => {
    return {
      value: code,
      label: countryList.getName(code),
    };
  });

  return countries;
};

export default useCountryList;
