import Label from "./Label";
import clsx from "clsx";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { SelectProps } from "../../utils/types";

const SelectField = ({
  register,
  name,
  rules = {},
  placeholder,
  label = null,
  required = false,
  errors = null,
  options = [],
}: SelectProps) => {
  return (
    <div className={"mb-4"}>
      {label && <Label labelFor={name ?? ""}>{label}</Label>}
      <div className={"relative"}>
        <select
          {...register(name ?? "", { required, ...rules })}
          aria-invalid={errors && errors[name ?? ""] ? "true" : "false"}
          placeholder={placeholder ?? ""}
          id={name ?? ""}
          className={clsx(
            "w-full rounded-md h-10 border-gray-300 focus:ring-0 focus:border-black px-4 py-2 placeholder-gray-500 ",
            errors &&
              errors[name ?? ""] &&
              "border-red-500 focus:border-red-500"
          )}
          defaultValue={""}
        >
          {placeholder && (
            <option value={""} disabled>
              {placeholder}
            </option>
          )}
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {errors && errors[name ?? ""] && (
          <div
            className={
              "absolute user-select-none inset-y-0 right-6 pr-3 flex items-center"
            }
          >
            <ExclamationCircleIcon className={"w-5 h-5 text-red-500"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectField;
