import { ReactNode } from "react";
import ValidateDomain from "../functional/ValidateDomain";

interface Props {
  children: ReactNode;
}

const ApplicationSkeleton = ({ children }: Props) => {
  return (
    <ValidateDomain>
      <div className={"my-12"}>{children}</div>
    </ValidateDomain>
  );
};

export default ApplicationSkeleton;
