import { ReactNode } from "react";

interface Props {
  labelFor?: string;
  children?: ReactNode;
}

const Label = ({ labelFor = "", children }: Props) => {
  return (
    <label htmlFor={labelFor} className={"text-gray-500 text-sm mb-1 block"}>
      {children}
    </label>
  );
};

export default Label;
