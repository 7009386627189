import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PaymentService from "../../../services/PaymentService";
import { RootState } from "../../index";
import { Status } from "../../../utils/types";
import { loadStripe, Stripe } from "@stripe/stripe-js";


export interface PaymentGatewayStripeConfig {
  client_secret: string;
  publishable_key: string;
  stripe?: Stripe;
}

export interface PaymentGatewayConfig {
  stripe?: PaymentGatewayStripeConfig;
}

export interface PaymentInitialData {
  invoice_number: number;
  date: string;
  due_date: string;
  amount: number;
  currency: string;
  payment_gateway: PaymentGatewayConfig;
}

interface SinglePaymentState {
  status: Status;
  paymentData: PaymentInitialData | null;
  paymentFormState: string;
  error: string | null;
  paymentProcessingStep: string;
  paymentProcessingError: string | null;
}

const initialState: SinglePaymentState = {
  status: "idle",
  paymentData: null,
  paymentFormState: "idle",
  error: null,
  paymentProcessingStep: "initial",
  paymentProcessingError: null,
};

export const initiatePayment = createAsyncThunk(
  "singlePayment/initiatePayment",
  async (token: string) => {
    return new PaymentService().initiatePayment(token).then(async (response) => {
      if (response.payment_gateway.stripe?.publishable_key) {
        response.payment_gateway.stripe.stripe = await loadStripe(response.payment_gateway.stripe.publishable_key);
      }
      return response;
    });
  }
);

const singlePaymentSlice = createSlice({
  name: "singlePayment",
  initialState,
  reducers: {
    submitPaymentForm: (state) => {
      state.paymentFormState = "submitting";
    },
    setPaymentFormIsSubmitting: (state, action) => {
      state.paymentFormState = action.payload ? "submitting" : "idle";
    },
    setPaymentProcessingStep: (state, action) => {
      state.paymentProcessingStep = action.payload;
    },
    setPaymentProcessingError: (state, action) => {
      state.paymentProcessingError = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(initiatePayment.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
        state.paymentProcessingStep = "initial";
        state.paymentProcessingError = null;
      })
      .addCase(initiatePayment.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.paymentData = action.payload;
      })
      .addCase(initiatePayment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error?.message || null;
      });
  },
});


export const getSinglePaymentData = (state: RootState) => {
  return state.singlePayment.paymentData;
};

export const paymentDataIsLoading = (state: RootState) => {
  return state.singlePayment.status === "loading";
};

export const paymentDataInitiationError = (state: RootState) => {
  if (state.singlePayment.status === "failed") return state.singlePayment.error;
};

export const isPaymentFormSubmitting = (state: RootState) => {
  return state.singlePayment.paymentFormState === "submitting";
};

export const isPaymentProcessing = (state: RootState) => {
  return state.singlePayment.paymentProcessingStep === "processing";
};

export const getPaymentProcessingError = (state: RootState) => {
  return state.singlePayment.paymentProcessingError;
};

export default singlePaymentSlice.reducer;

export const {
  submitPaymentForm,
  setPaymentFormIsSubmitting ,
  setPaymentProcessingStep,
  setPaymentProcessingError,
} = singlePaymentSlice.actions;
