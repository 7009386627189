const VisaCard = () => {
  return (
    <svg
      width="29"
      height="19"
      viewBox="0 0 29 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28.4999" height="19" rx="1.9" fill="#00579F" />
      <path
        d="M19.2962 6.23052C18.9452 6.09126 18.3885 5.9375 17.7001 5.9375C15.9426 5.9375 14.705 6.87465 14.6975 8.2145C14.6829 9.20304 15.5835 9.7521 16.2571 10.0817C16.9457 10.4185 17.1797 10.6384 17.1797 10.9386C17.1727 11.3997 16.6233 11.6122 16.111 11.6122C15.4005 11.6122 15.0198 11.5026 14.4412 11.2461L14.2069 11.1361L13.9579 12.6811C14.3752 12.8713 15.1442 13.04 15.9426 13.0474C17.8099 13.0474 19.0256 12.1248 19.04 10.6969C19.0471 9.91345 18.5715 9.31311 17.5462 8.82253C16.9238 8.50762 16.5426 8.29528 16.5426 7.97306C16.5499 7.68014 16.865 7.38011 17.5676 7.38011C18.1461 7.36542 18.5712 7.50448 18.8932 7.64365L19.0542 7.71673L19.2962 6.23052Z"
        fill="white"
      />
      <path
        d="M12.845 12.9448H11.0653L12.1784 6.06226H13.958L12.845 12.9448Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8582 6.06226H22.4817C22.0572 6.06226 21.7347 6.18663 21.5515 6.63331L18.9081 12.9447H20.7754C20.7754 12.9447 21.0828 12.0953 21.1489 11.9123H23.4338C23.4849 12.154 23.6461 12.9447 23.6461 12.9447H25.2939L23.8582 6.06226ZM21.6615 10.5066C21.808 10.1112 22.372 8.5809 22.372 8.5809C22.369 8.58679 22.392 8.52321 22.4262 8.4284L22.4266 8.42724L22.4268 8.42648C22.4779 8.28487 22.5538 8.07468 22.6061 7.92197L22.7305 8.51501C22.7305 8.51501 23.0675 10.1625 23.1407 10.5066H21.6615Z"
        fill="white"
      />
      <path
        d="M9.57892 6.06226L7.83609 10.7555L7.64564 9.80368L7.64547 9.80308L7.02321 6.6408C6.92073 6.20123 6.60582 6.07676 6.21772 6.06226H3.35449L3.3252 6.20132C4.02353 6.3799 4.64812 6.63723 5.19539 6.95719L6.78157 12.9376H8.66347L11.4607 6.06226H9.57892Z"
        fill="white"
      />
    </svg>
  );
};

export default VisaCard;
