import { createBrowserRouter, Navigate } from "react-router-dom";
import LoginView from "../components/views/guest/LoginView";
import StyleGuide from "../components/views/StyleGuide";
import NotFound from "../components/views/NotFound";
import CheckEmailView from "../components/views/guest/CheckEmailView";
import CreateAccountView from "../components/views/auth/CreateAccountView";
import AuthLayout from "../components/layout/AuthLayout";
import DashboardView from "../components/views/auth/DashboardView";
import ValidateCodeView from "../components/views/auth/ValidateCodeView";
import EditAccount from "../components/views/popups/EditAccount";
import NewPaymentMethod from "../components/views/popups/NewPaymentMethod";
import EditPaymentMethod from "../components/views/popups/EditPaymentMethod";
import ManagePlansView from "../components/views/auth/ManagePlansView";
import CancelSubscription from "../components/views/popups/CancelSubscription";
import DisablePaymentCard from "../components/views/popups/DisablePaymentCard";
import PayView from "../components/views/guest/PayView";
import { store } from "../store";
import { initiatePayment } from "../store/reducers/payment/singlePaymentSlice";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <DashboardView />,
        children: [
          {
            path: "/edit-account",
            element: <EditAccount />,
          },
          {
            path: "/payment-methods",
            element: <NewPaymentMethod />,
          },
          {
            path: "/payment-methods/:uuid",
            element: <EditPaymentMethod />,
          },
          {
            path: "/payment-methods/:uuid/disable",
            element: <DisablePaymentCard />,
          },
          {
            path: "/subscriptions/:uuid/cancel",
            element: <CancelSubscription />,
          },
        ],
      },
      {
        path: "/subscriptions",
        element: <ManagePlansView />,
      },
      {
        path: "/subscriptions/:uuid",
        element: <ManagePlansView step={"edit-plan"} />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginView />,
  },
  {
    path: "/check-your-email",
    element: <CheckEmailView />,
  },
  {
    path: "/validate/:token",
    element: <ValidateCodeView />,
  },
  {
    path: "/styleguide",
    element: <StyleGuide />,
  },
  {
    path: "/pay/:paymentToken",
    element: <PayView />,
    loader: ({ params }) => {
      store.dispatch(initiatePayment(params.paymentToken));
      return null;
    },
  },
  {
    loader: () => {
      // if not logged in
      return <Navigate to="/login" />;
    },
    element: <AuthLayout />,
    children: [
      {
        path: "/create-account",
        element: <CreateAccountView />,
      },
      {
        path: "/create-account/checkout",
        element: <CreateAccountView step={"create-account"} />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
