import { SubscriptionOption } from "../utils/types";
import { SubscriptionData } from "../store/reducers/subscriptions/subscriptionSlice";
import { useMoneyFormat } from "./useMoneyFormat";
import useDateFormat from "./useDateFormat";
import useFormats from "./useFormats";
import { TagProps } from "../components/ui/Tag";

const useSubscriptionFormat = () => {
  const { formatMoney } = useMoneyFormat();
  const { formatDate } = useDateFormat();
  const { planTermsBillingInterval, planTermsPeriod } = useFormats();

  const subscriptionTitle = (subscription: SubscriptionData) => {
    return (
      subscription.quantity +
      "x " +
      subscription.plan.name +
      " - " +
      formatMoney(
        parseFloat(subscription.terms.price),
        subscription.terms.currency
      ) +
      " / " +
      planTermsPeriod(subscription.terms)
    );
  };

  const subscriptionDescription = (subscription: SubscriptionData) => {
    if (subscription.status === "not_starting")
      return `Not starting until ${formatDate(subscription.next_renewal_date)}`;

    if (subscription.status === "not_renewing")
      return `Not renewing after ${formatDate(subscription.next_renewal_date)}`;

    if (subscription.status === "expired")
      return `Expired on ${formatDate(subscription.next_renewal_date)}`;

    if (subscription.status === "scheduled")
      return `Scheduled to start on ${formatDate(
        subscription.next_renewal_date
      )}`;

    return `Billed ${planTermsBillingInterval(
      subscription.terms
    ).toLowerCase()} - Next renew ${formatDate(
      subscription.next_renewal_date
    )}`;
  };

  const subscriptionTag = (subscription: SubscriptionData) => {
    let tagProps: TagProps;
    switch (subscription.status) {
      case "active":
        tagProps = {
          color: "green",
          text: "Active",
        };
        break;
      case "scheduled":
        tagProps = {
          color: "yellow",
          text: "Scheduled",
        };
        break;
      case "not_renewing":
        tagProps = {
          color: "blue",
          text: "Not Renewing",
        };
        break;
      case "not_starting":
        tagProps = {
          color: "blue",
          text: "Not Starting",
        };
        break;
      case "expired":
        tagProps = {
          color: "red",
          text: "Expired",
        };
        break;
    }

    return tagProps as TagProps;
  };

  return {
    subscriptionTitle,
    subscriptionDescription,
    subscriptionTag,
  };
};

export default useSubscriptionFormat;
