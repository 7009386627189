import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import CreditCards from "../../ui/icons/CreditCards";
import Label from "../../ui/Label";
import useCreditCardMask from "../../../hooks/useCreditCardMask";
import InputField from "../../ui/InputField";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import clsx from "clsx";
import { setCreditCardData } from "../../../store/reducers/checkout/checkoutSlice";

const PaymentCardForm = () => {
  const isSubmitting = useAppSelector((state) => state.checkout.isSubmitting);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const form = useRef<HTMLFormElement>(null);

  const onSubmit = async (data: any) => {
    dispatch(setCreditCardData(data));
  };

  useEffect(() => {
    if (isSubmitting) {
      console.log("Submitting payment card...");
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, isSubmitting]);

  const { cardNumberMask, expirationDateMask, cvcMask } = useCreditCardMask();

  const handleCardNumberChange = (event: any) => {
    event.target.value = cardNumberMask(event.target.value);
  };

  const handleExpirationDateChange = (event: any) => {
    event.target.value = expirationDateMask(event.target.value);
  };

  const handleCvcChange = (event: any) => {
    event.target.value = cvcMask(event.target.value);
  };

  return (
    <form
      ref={form}
      method={"post"}
      action={"/"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={"mb-4"}>
        <Label>Card information</Label>
        <div className={"relative"}>
          <CreditCards
            className={"absolute top-1/2 right-3 -translate-y-1/2"}
          />
          <input
            required={true}
            {...register("cardNumber", { required: true })}
            className={clsx(
              "border pl-4 pr-20 border-gray-300 rounded-t-md p-2 w-full shadow-none focus:outline-black focus:outline-0 focus:ring-black",
              errors &&
                errors.cardNumber &&
                "border-red-500 focus:border-red-500"
            )}
            onChange={handleCardNumberChange}
            placeholder={"XXXX XXXX XXXX XXXX"}
          />
        </div>
        <div className={"flex"}>
          <div className="w-1/2">
            <input
              required={true}
              {...register("cardExpiry", { required: true })}
              className={clsx(
                "border pl-4 pr-20 border-gray-300 rounded-bl-md border-t-0 p-2 w-full focus:outline-0 focus:ring-black",
                errors &&
                  errors.cardExpiry &&
                  "border-red-500 focus:border-red-500"
              )}
              onChange={handleExpirationDateChange}
              placeholder={"MM / YY"}
            />
          </div>
          <div className="w-1/2">
            <input
              required={true}
              {...register("cardCvc", { required: true })}
              className={clsx(
                "border pl-4 pr-20 border-gray-300 rounded-br-md border-l-0 border-t-0 p-2 w-full focus:outline-0 focus:ring-black",
                errors &&
                  errors.cardCvc &&
                  "border-red-500 focus:border-red-500"
              )}
              onChange={handleCvcChange}
              placeholder={"CVC"}
            />
          </div>
        </div>
      </div>
      <Label labelFor={"cardName"}>Card holder name</Label>
      <InputField
        required={true}
        name={"cardName"}
        placeholder={"John Doe"}
        register={register}
        errors={errors}
      />
    </form>
  );
};

export default PaymentCardForm;
