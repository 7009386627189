import Container from "./Container";
import React from "react";
import Logo from "../ui/Logo";
import PoweredBy from "../ui/PoweredBy";
import ApplicationSkeleton from "./ApplicationSkeleton";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { hasValidToken } from "../../store/reducers/auth/authSlice";

type Hide = "poweredBy" | "logo";

interface Props {
  children: React.ReactNode;
  disableMiddleware?: boolean;
  hide?: Hide[];
}

const GuestLayout = ({
  children,
  disableMiddleware = false,
  hide = [],
}: Props) => {
  const isLoggedIn = useAppSelector(hasValidToken);

  if (isLoggedIn && !disableMiddleware) {
    return <Navigate to={"/create-account"} />;
  }

  const hideLogo = hide.length > 0 ? hide.includes("logo") : false;

  return (
    <ApplicationSkeleton>
      <div className={"flex items-center justify-center"}>
        <Container size={"small"}>
          {!hideLogo && (
            <div className={"mb-6 flex justify-center"}>
              <Logo />
            </div>
          )}
          {children}
          <div className={"mt-6"}>
            <PoweredBy />
          </div>
        </Container>
      </div>
    </ApplicationSkeleton>
  );
};

export default GuestLayout;
