import { PlanTerm } from "../../../utils/types";
import { getAllPlans, getPlanTermByUuid } from "../plans/plansSlice";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

export interface CreateAccountData {
  firstName: string;
  lastName: string;
  email: string;
  legalAddress: string;
  legalCity: string;
  legalZipCode: string;
  companyName: string;
  country: string;
  vatNumber: string;
}

export type AccountStep =
  | "plans"
  | "create-account"
  | "edit-plan"
  | "create-plan"
  | "submitting"
  | "confirmation";

interface CreditCardData {
  cardNumber: string;
  cardName: string;
  cardExpiry: string;
  cardCvc: string;
}

interface CheckoutStateProps {
  checkoutStep: AccountStep;
  planTerm: string | null;
  quantity: number;
  accountData: CreateAccountData | null;
  creditCardData: CreditCardData | null;
  isSubmitting: boolean;
  validated: boolean;
}

const initialState: CheckoutStateProps = {
  checkoutStep: "plans",
  planTerm: null,
  quantity: 1,
  accountData: null,
  creditCardData: null,
  isSubmitting: false,
  validated: false,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setPlanTerm(state, action) {
      state.planTerm = action.payload;
    },
    setQuantity(state, action) {
      state.quantity = action.payload;
    },
    setCheckoutStep(state, action) {
      state.checkoutStep = action.payload;
    },
    setAccountData(state, action) {
      state.accountData = action.payload as CreateAccountData;
    },
    setCreditCardData(state, action) {
      state.creditCardData = action.payload as CreditCardData;
    },
    setIsSubmitting(state, action) {
      state.isSubmitting = action.payload;
    },
    setValidated(state, action) {
      state.validated = action.payload;
    },
    resetCheckout(state) {
      state.checkoutStep = "plans";
      state.planTerm = null;
      state.quantity = 1;
      state.accountData = null;
      state.creditCardData = null;
      state.isSubmitting = false;
      state.validated = false;
    },
  },
});

export const getTotal = (state: RootState) => {
  const { planTerm, quantity } = state.checkout;

  if (planTerm === null) {
    return 0;
  }

  const selectedPlanTerm = getPlanTermByUuid(state)(planTerm) as PlanTerm;

  if (selectedPlanTerm) {
    return parseFloat(selectedPlanTerm.price) * quantity;
  }
  return 0;
};

export const getSelectedPlanTerm = (state: RootState) => {
  const { planTerm } = state.checkout;

  if (planTerm === null) {
    return null;
  }

  return getPlanTermByUuid(state)(planTerm) as PlanTerm;
};

export const getSelectedPlanName = (state: RootState) => {
  const { planTerm } = state.checkout;

  if (planTerm === null) {
    return null;
  }

  const selectedPlanTerm = getPlanTermByUuid(state)(planTerm) as PlanTerm;

  // get the Plan from the PlanTerm in plan.planterms_set
  const plan = getAllPlans(state).find((plan) => {
    return plan.planterms_set.find((planTerm) => {
      return planTerm.uuid === selectedPlanTerm.uuid;
    });
  });

  return plan?.name || null;
};

export const canCheckout = (state: RootState) => {
  const { accountData, creditCardData, planTerm } = state.checkout;

  if (accountData === null || creditCardData === null || planTerm === null) {
    return false;
  }

  return true;
};

export const planTermIsSelected = (state: RootState) =>
  state.checkout.planTerm !== null;

export const creditCardDataToStripe = (state: RootState) => {
  const { creditCardData } = state.checkout;

  if (creditCardData === null) {
    return null;
  }

  return {
    name: creditCardData.cardName,
    // number remove spaces
    number: parseInt(creditCardData.cardNumber.replace(/\s/g, "")),
    exp_month: parseInt(creditCardData.cardExpiry.split("/")[0]),
    exp_year: parseInt(creditCardData.cardExpiry.split("/")[1]),
    cvc: creditCardData.cardCvc,
  };
};

export const {
  setPlanTerm,
  setQuantity,
  setCheckoutStep,
  setAccountData,
  setCreditCardData,
  setIsSubmitting,
  setValidated,
} = checkoutSlice.actions as any;

export default checkoutSlice.reducer;
