interface Props {
  count?: number;
  height?: number;
}

const LoadingStates = ({ count = 1, height = 50 }: Props) => {
  return (
    <div className={"space-y-4"}>
      {[...Array(count)].map((_, index) => {
        return (
          <div
            style={{ height }}
            key={index}
            className={"w-full rounded-lg bg-gray-200 animate-pulse"}
          ></div>
        );
      })}
    </div>
  );
};

export default LoadingStates;
