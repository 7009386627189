import { ReactNode } from "react";
import clsx from "clsx";

interface Props {
  children?: ReactNode;
  className?: string;
}

const ButtonGroup = ({ children, className }: Props) => {
  return (
    <div className={clsx("flex items-end flex-wrap space-x-4", className)}>
      {children}
    </div>
  );
};

export default ButtonGroup;
