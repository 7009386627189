import { CreateAccountData } from "../store/reducers/checkout/checkoutSlice";
import AccountService from "./AccountService";
import SubscriptionService from "./SubscriptionService";
import { PlanTerm } from "../utils/types";
import StripeService, { CreateCardTokenData } from "./StripeService";
import PaymentMethodService from "./PaymentMethodService";
import toast from "react-hot-toast";

interface NewAccountData {
  account: CreateAccountData | null;
  plan: PlanTerm | null;
  quantity: number;
  card_data: CreateCardTokenData | null;
  stripe_token: string;
}

const createNewAccount = async (data: NewAccountData | null) => {
  if (!data) throw new Error("No data provided");

  const accountService = new AccountService();
  const subscriptionService = new SubscriptionService();
  const stripeService = new StripeService(data.stripe_token);
  const paymentMethodService = new PaymentMethodService();
  let paymentCreated = false;
  let paymentMethod: any = null;

  try {
    const cardToken = await stripeService.createCardToken(
      data.card_data as CreateCardTokenData
    );
    paymentMethod = await stripeService.createPaymentMethod(cardToken.id);
    paymentCreated = true;
  } catch (error) {}

  return await accountService
    .createAccount(data.account)
    .then(async (response) => {
      if (paymentCreated) {
        await paymentMethodService.createPaymentCard(
          response.uuid,
          paymentMethod?.id
        );
      }

      await subscriptionService
        .newSubscription({
          account: response?.code,
          terms: data.plan?.uuid || "",
          collection_method: "payment_card",
          quantity: data?.quantity,
        })
        .catch((error) => {
          toast("Could not create subscription");
        });
      // const info = await new SelfServiceAuth().info(data.token);
    })
    .catch((error) => {
      if (error.code === 409) {
        toast.error("Could not create account"); // maybe something else
      } else {
        toast.error("Could not create account");
      }
      throw new Error("Could not create account");
    });
};

export default createNewAccount;
