import GuestLayout from "../layout/GuestLayout";
import { PageTitle, Paragraph } from "../ui/Fonts";
import Button from "../ui/Button";

const NotFound = () => {
  return (
    <GuestLayout disableMiddleware={true} hide={["logo"]}>
      <div className={"text-center"}>
        <PageTitle>Page not found</PageTitle>
        <Paragraph className={"max-w-xs mx-auto"}>
          Sorry, the page you are looking for doesn’t exist or has been moved.
        </Paragraph>
        <Button href={"https://www.fenerum.com"} block={true}>
          Go to fenerum.com
        </Button>
      </div>
    </GuestLayout>
  );
};

export default NotFound;
