import { Paragraph, SectionHeading } from "./Fonts";
import { useMoneyFormat } from "../../hooks/useMoneyFormat";

interface Props {
  title: string;
  currency: string;
  description?: string;
  price?: number;
}

const PlanTermOption = ({
  title,
  currency,
  description = "",
  price = 0,
}: Props) => {
  const { formatMoney } = useMoneyFormat();
  return (
    <div className={"flex"}>
      <div className={"w-5/6"}>
        <SectionHeading className={"!mb-2"}>{title}</SectionHeading>
        {description && (
          <Paragraph className={"mt-0 !mb-0"}>{description}</Paragraph>
        )}
      </div>
      <div className={"w-1/6 flex justify-end"}>
        <Paragraph className={"!font-medium !text-black !text-base"}>
          {price && formatMoney(price, currency)}
        </Paragraph>
      </div>
    </div>
  );
};

export default PlanTermOption;
