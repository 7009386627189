import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

interface Props {
  message?: string | null;
}

const ErrorMessage = ({ message }: Props) => {
  return (
    <div className={"flex items-center justify-center mt-4"}>
      <ExclamationCircleIcon className={"w-5 h-5 text-red-500 shrink-0"} />
      <span className={"text-red-500 text-sm ml-2"}>
        {message ?? "Something went wrong"}
      </span>
    </div>
  );
};

export default ErrorMessage;
