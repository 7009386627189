const MasterCard = () => {
  return (
    <svg
      width="29"
      height="19"
      viewBox="0 0 29 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.375" width="28.4999" height="19" rx="1.9" fill="#151515" />
      <path
        d="M24.125 9.43367C24.125 12.6792 21.4992 15.3049 18.2537 15.3049C16.8838 15.3049 15.628 14.832 14.6332 14.0491C16.0031 12.9727 16.8675 11.3092 16.8675 9.43367C16.8675 7.55814 15.9868 5.89463 14.6332 4.81823C15.628 4.0354 16.8838 3.56244 18.2537 3.56244C21.4992 3.56244 24.125 6.2045 24.125 9.43367Z"
        fill="#F79E1B"
      />
      <path
        d="M14.6332 4.81823C14.6332 4.81823 14.6332 4.81823 14.6332 4.81823C15.9868 5.89463 16.8675 7.55814 16.8675 9.43367C16.8675 11.3092 16.0031 12.9727 14.6332 14.0491L14.6168 14.0491C13.2632 12.989 12.3825 11.3092 12.3825 9.43371C12.3825 7.55817 13.2632 5.89466 14.6168 4.81827C14.6168 4.81825 14.6168 4.81828 14.6168 4.81827L14.6332 4.81823Z"
        fill="#FF5F00"
      />
      <path
        d="M12.3825 9.43371C12.3825 7.55817 13.2632 5.89466 14.6168 4.81827C13.622 4.03544 12.3662 3.56246 10.9962 3.56246C7.75075 3.56246 5.125 6.18821 5.125 9.4337C5.125 12.6792 7.75075 15.3049 10.9962 15.3049C12.3662 15.3049 13.6219 14.8319 14.6168 14.0491C13.2632 12.989 12.3825 11.3092 12.3825 9.43371Z"
        fill="#EB001B"
      />
    </svg>
  );
};

export default MasterCard;
