import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BillingHistoryService from "../../../services/BillingHistoryService";
import { Status } from "../../../utils/types";
import { RootState } from "../../index";

export type InvoiceStatus =
  | "paid"
  | "due"
  | "open"
  | "nonpayment"
  | "contested"
  | "chargeback"
  | "debt_collection";

export interface InvoiceData {
  uuid: string;
  invoice_number: number;
  balance: number;
  due_date: string;
  date: string;
  total: number;
  subtotal: number;
  vat: number;
  currency: string;
  status: InvoiceStatus;
  payment_link?: string;
  has_pdf: boolean;
}

interface BillingHistoryState {
  invoices: InvoiceData[] | null;
  status: Status;
  downloadStatus: Status;
  downloadFile: string | null;
  error: string | null;
  downloadingUuid: InvoiceData | null;
}

const initialState: BillingHistoryState = {
  invoices: null,
  status: "idle",
  downloadFile: null,
  downloadStatus: "idle",
  downloadingUuid: null,
  error: null,
};

export const fetchInvoices = createAsyncThunk(
  "billingHistory/fetchInvoices",
  async () => {
    return new BillingHistoryService().getInvoices();
  }
);

export const downloadInvoice = createAsyncThunk(
  "billingHistory/downloadInvoice",
  async (invoice: InvoiceData) => {
    return new BillingHistoryService().downloadInvoice(invoice?.uuid);
  }
);

export const getDownloadInvoice = (state: RootState) => {
  return (
    state.billingHistory.invoices &&
    state.billingHistory.invoices.filter(
      (invoice) => invoice.uuid === state.billingHistory.downloadingUuid?.uuid
    )[0]
  );
};

const BillingHistorySlice = createSlice({
  name: "billingHistory",
  initialState,
  reducers: {
    setDownloadUuid(state, action) {
      state.downloadingUuid = action.payload;
    },
    clearFile(state) {
      state.downloadFile = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInvoices.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.invoices = action.payload;
      })
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error?.message || null;
      })
      .addCase(downloadInvoice.pending, (state, action) => {
        state.downloadStatus = "loading";
      })
      .addCase(downloadInvoice.fulfilled, (state, action) => {
        state.downloadStatus = "succeeded";
        state.downloadFile = action.payload;
      })
      .addCase(downloadInvoice.rejected, (state, action) => {
        state.downloadStatus = "failed";
        state.error = action?.error?.message || null;
      });
  },
});

export default BillingHistorySlice.reducer;

export const { setDownloadUuid, clearFile } = BillingHistorySlice.actions;

export const getInvoices = (state: BillingHistoryState) => state.invoices;
