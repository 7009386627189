import SelfServiceAuth from "../services/SelfServiceAuth";

export default function useSendAuthEmail() {
  return async (email: string) => {
    await new SelfServiceAuth().initiateSelfService(email).then((response) => {
      console.log(response);
    });
    await console.log("Sending email to: ", email);
  };
}
