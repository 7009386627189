const UseBalanceColor = (balance: number) => {
  switch (true) {
    case balance < 0:
      return "text-green-500";
    case balance > 0:
      return "text-red-500";
    default:
      return "text-gray-900";
  }
};

export default UseBalanceColor;
